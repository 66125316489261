import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { LoadScript } from "@react-google-maps/api";

import OverlayDropdown from "../ui/Input/OverlayDropdown/OverlayDropdown";
import TextInputOverlay from "../ui/Input/TextInputOverlay/TextInputOverlay";
import TextAreaOverlay from "../ui/Input/TextAreaOverlay/TextAreaOverlay";
import Button from "../ui/Button/Button";
import Loading from "../ui/loading/Loading";

import { constants } from "../../locales/constant";
import { keys } from "../../locales/localeskeys";

import classes from "./Contact.module.scss";
import Offices from "../Offices/Offices";
import { useNavigate } from "react-router-dom";
import { getGoogleMapKey, getOffices, googleMapKeySelector, loadingGoogleMapKeySelector } from "../../store/offices";

const Contact = () => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const googleMapKey = useSelector(googleMapKeySelector);
	const loadingGoogleMapKey = useSelector(loadingGoogleMapKeySelector);

	const [firstNameState, setFirstName] = useState({ value: "", isValid: false });
	const [lastNameState, setLastName] = useState({ value: "", isValid: false });
	const [companyNameState, setCompanyName] = useState({ value: "", isValid: false });
	const [emailState, setEmail] = useState({ value: "", isValid: false });
	const [phoneNumberState, setPhoneNumber] = useState({ value: "", isValid: false });
	const [detailstate, setDetails] = useState({ value: "", isValid: false });
	const [alreadyCustomer, setAlreadyCustomer] = useState(-1);
	const [isCompany, setIsCompany] = useState(-1);
	const [displayFormNotValid, setDisplayFormNotValid] = useState(false);
	const [isCaptchaValid, setIsCaptchaValid] = useState(false);
	const [fieldChanged, setFieldChanged] = useState(false);
	const [formSubmitted, setFormSubmitted] = useState(false);

	const isFormValid =
		firstNameState.isValid &&
		lastNameState.isValid &&
		companyNameState.isValid &&
		emailState.isValid &&
		phoneNumberState.isValid &&
		detailstate.isValid &&
		alreadyCustomer !== -1 &&
		isCompany !== -1;

	const companyNameChangeHandler = (event) => {
		setCompanyName({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const firstNameChangeHandler = (event) => {
		setFirstName({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const lastNameChangeHandler = (event) => {
		setLastName({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const emailChangeHandler = (event) => {
		const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,15}$/;
		setEmail({
			value: event.target.value,
			isValid: event.target.value !== "" && emailRegExp.test(event.target.value.trim()),
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const phoneNumberChangeHandler = (event) => {
		setPhoneNumber({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const detailsChangeHandler = (event) => {
		setDetails({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const captchaChangeHandler = (value) => {
		if (value) {
			setIsCaptchaValid(value);
		}
	};

	const entityChangeHandler = (event) => {
		setAlreadyCustomer(event.target.value);
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const statusChangeHandler = (event) => {
		setIsCompany(event.target.value);
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	useEffect(() => {
		if (formSubmitted) {
			const timer = setTimeout(() => {
				setFormSubmitted(false);
			}, 4000);
			window.scrollTo(0, 0);

			return () => {
				clearTimeout(timer);
				navigate({ pathname: `/` });
			};
		}
	}, [formSubmitted]);

	useEffect(() => {
		dispatch(getOffices());
		if (!googleMapKey) {
			dispatch(getGoogleMapKey());
		}
	}, []);

	const resetForm = (
		setFirstName,
		setLastName,
		setCompanyName,
		setEmail,
		setPhoneNumber,
		setDetails,
		setAlreadyCustomer,
		setIsCompany,
		setDisplayFormNotValid,
		setIsCaptchaValid,
		setFieldChanged
	) => {
		setFirstName({ value: "", isValid: false });
		setLastName({ value: "", isValid: false });
		setCompanyName({ value: "", isValid: false });
		setEmail({ value: "", isValid: false });
		setPhoneNumber({ value: "", isValid: false });
		setDetails({ value: "", isValid: false });
		setAlreadyCustomer(-1);
		setIsCompany(-1);
		setDisplayFormNotValid(false);
		setIsCaptchaValid(false);
		setFieldChanged(false);
	};

	const closeSuccessHandler = () => {
		setFormSubmitted(false);
		navigate({ pathname: `/` });
	};

	const submitHandler = (event) => {
		if (!isCaptchaValid || !isFormValid || !fieldChanged) {
			event.preventDefault();
		} else {
			resetForm(
				setFirstName,
				setLastName,
				setCompanyName,
				setEmail,
				setPhoneNumber,
				setDetails,
				setAlreadyCustomer,
				setIsCompany,
				setDisplayFormNotValid,
				setIsCaptchaValid,
				setFieldChanged
			);
			setFormSubmitted(true);
		}
	};

	return (
		<Fragment>
			{!formSubmitted && (
				<>
					{loadingGoogleMapKey && <Loading type="bounce" />}
					{!loadingGoogleMapKey && (
						<LoadScript googleMapsApiKey={googleMapKey} libraries={["places"]}>
							<div
								className={classes.banner}
								style={{
									backgroundImage: `url(${
										lngId.includes("fr")
											? "https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/ContactUS/Contacteznous_Header.png"
											: "https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/ContactUS/Contactus_Header.png"
									})`,
								}}
							/>
							<div className={classes.wrapper}>
								<main>
									<div className={classes.innerMain}>
										<div className={classes.container}>
											<div className={classes.title}>
												<h1>{t(keys.CONTACT.TITLE)}</h1>
												<p>{t(keys.CONTACT.DESCRIPTION)}</p>
												<h2>{t(keys.CONTACT.TITLE_FORM)}</h2>
											</div>
											<div className={classes.content}>
												<div className={classes.formWrapper}>
													<form
														action="https://www2.adfast.store/l/534932/2022-10-13/3dtzrn"
														onSubmit={submitHandler}
													>
														<fieldset>
															<legend>{t(keys.CONTACT.TITLE)}</legend>
															<OverlayDropdown
																id="signup_source"
																label={t(keys.AUTH.SIGN_UP.ALREADY_ACCOUNT_ADFAST)}
																onChange={entityChangeHandler}
																className={classes.inputLayout}
																dropdown={classes.dropdown}
																value={alreadyCustomer}
																name="Already_Customer"
															>
																<option value={-1} disabled>
																	{t(keys.AUTH.SIGN_UP.ALREADY_ACCOUNT_ADFAST)}
																</option>
																<option value={0}>{t(keys.GLOBAL.COMMON.NO)}</option>
																<option value={1}>{t(keys.GLOBAL.COMMON.YES)}</option>
															</OverlayDropdown>
															<OverlayDropdown
																id="signup_source"
																label={t(keys.CONTACT.IS_COMPANY)}
																onChange={statusChangeHandler}
																className={classes.inputLayout}
																dropdown={classes.dropdown}
																value={isCompany}
																name="Customer_Type"
															>
																<option value={-1} disabled>
																	{t(keys.CONTACT.IS_COMPANY)}
																</option>
																<option value={0}>{t(keys.GLOBAL.COMMON.NO)}</option>
																<option value={1}>{t(keys.GLOBAL.COMMON.YES)}</option>
															</OverlayDropdown>
															<TextInputOverlay
																id="FIRST_NAME"
																label={t(keys.HOME.NEWSLETTER.FIRSTNAME)}
																isValid={firstNameState.isValid}
																value={firstNameState.value}
																className={classes.inputLayout}
																onChange={firstNameChangeHandler}
																error={
																	displayFormNotValid &&
																	t(keys.AUTH.SIGN_UP.FILL_MANDATORY_FIELD)
																}
																mandatory
																name="FirstName"
															/>
															<TextInputOverlay
																id="LAST_NAME"
																label={t(keys.HOME.NEWSLETTER.LASTNAME)}
																isValid={lastNameState.isValid}
																value={lastNameState.value}
																className={classes.inputLayout}
																onChange={lastNameChangeHandler}
																error={
																	displayFormNotValid &&
																	t(keys.AUTH.SIGN_UP.FILL_MANDATORY_FIELD)
																}
																mandatory
																name="LastName"
															/>
															<TextInputOverlay
																id="COMPANY_NAME"
																label={t(keys.AUTH.SIGN_UP.COMPANY_NAME)}
																isValid={companyNameState.isValid}
																value={companyNameState.value}
																className={classes.inputLayout}
																onChange={companyNameChangeHandler}
																error={
																	displayFormNotValid &&
																	t(keys.AUTH.SIGN_UP.FILL_MANDATORY_FIELD)
																}
																mandatory
																name="Company"
															/>
															<TextInputOverlay
																id="EMAIL"
																label={t(keys.AUTH.SIGN_UP.EMAIL)}
																type="email"
																isValid={emailState.isValid}
																value={emailState.value}
																className={classes.inputLayout}
																onChange={emailChangeHandler}
																error={
																	displayFormNotValid &&
																	t(keys.AUTH.SIGN_UP.FILL_VALID_EMAIL)
																}
																mandatory
																name="Email"
															/>
															<TextInputOverlay
																id="PHONE"
																label={t(keys.AUTH.SIGN_UP.PHONE)}
																isValid={phoneNumberState.isValid}
																value={phoneNumberState.value}
																className={classes.inputLayout}
																labelclass={classes.labelclass}
																onChange={phoneNumberChangeHandler}
																error={
																	displayFormNotValid &&
																	t(keys.AUTH.SIGN_UP.FILL_MANDATORY_FIELD)
																}
																mandatory
																name="Phone"
															/>
														</fieldset>
														<TextAreaOverlay
															className={classes.text}
															id="DETAILS"
															label={t(keys.CONTACT.HOW_HELP)}
															isValid={detailstate.isValid}
															value={detailstate.value}
															onChange={detailsChangeHandler}
															error={
																displayFormNotValid &&
																t(keys.AUTH.SIGN_UP.FILL_MANDATORY_FIELD)
															}
															mandatory
															name="Message"
														/>
														<div className={classes.captcha}>
															<ReCAPTCHA
																sitekey={constants.sitekey}
																onChange={captchaChangeHandler}
															/>
														</div>
														<div className={classes.btn}>
															<Button
																type="submit"
																value="submit"
																size="big"
																className={classes.button}
															>
																{t(keys.AUTH.FORGOT_PASSWORD.SUBMIT)}
															</Button>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
									<div className={classes.mapLayout}>
										<h1 className={classes.title}>{t(keys.CONTACT.OUR_OFFICE)}</h1>
										<Offices />
									</div>
								</main>
							</div>
						</LoadScript>
					)}
				</>
			)}
			{formSubmitted && (
				<div className={classes.wrapper}>
					<div className={classes.success}>
						<div className={classes.successIcon}></div>
						<button className={classes.closeButton} onClick={closeSuccessHandler}>
							✕
						</button>
						<div className={classes.successTitle}>
							<h1>{t(keys.PARTNERS.MODAL.SUCCESS_TITLE)}</h1>
						</div>
						<div className={classes.successSubTitle}>
							<p>{t(keys.PARTNERS.MODAL.SUCCESS_FIRST_SUBTITLE)}</p>
							<p>{t(keys.PARTNERS.MODAL.SUCCESS_SECOND_SUBTITLE)}</p>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default Contact;
