import { keys } from "./localeskeys";

export const constants = {
	API_SERVER: "https://www.adfast.store/api",
	TRACKING_ID: "UA-48652503-6",
	sitekey: "6LcFi3YdAAAAANzaLxvj6fkbwk1XQJG11a5TF_Ia",
	DEFAULT_IMG: "https://adfastcorpcdnak.azureedge.net/b2b/Product_Pictures/Noimage_b2b.png",
	IMG_PREVIEW: "https://adfastcorpcdnak.azureedge.net/b2b/HomePage/Googlepreview.png",

	calculatorData: [
		{
			dimension: "3/16 x 1/4",
			cartridge: 33,
			sausage: 65,
		},
		{
			dimension: "3/16 x 3/8",
			cartridge: 23,
			sausage: 44,
		},
		{
			dimension: "3/16 x 1/2",
			cartridge: 16,
			sausage: 32.5,
		},
		{
			dimension: "3/16 x 5/8",
			cartridge: 13,
			sausage: 26.4,
		},
		{
			dimension: "3/16 x 3/4",
			cartridge: 11,
			sausage: 21.3,
		},
		{
			dimension: "1/4 x 1/4",
			cartridge: 25,
			sausage: 48.7,
		},
		{
			dimension: "1/4 x 3/8",
			cartridge: 16,
			sausage: 32.5,
		},
		{
			dimension: "1/4 x 1/2",
			cartridge: 12,
			sausage: 24.3,
		},
		{
			dimension: "1/4 x 5/8",
			cartridge: 10,
			sausage: 19.3,
		},
		{
			dimension: "1/4 x 3/4",
			cartridge: 8.3,
			sausage: 16.2,
		},
		{
			dimension: "1/4 x 1",
			cartridge: 6.2,
			sausage: 12.1,
		},
		{
			dimension: "3/8 x 3/8",
			cartridge: 11,
			sausage: 21.3,
		},
		{
			dimension: "3/8 x 1/2",
			cartridge: 8.3,
			sausage: 16.2,
		},
		{
			dimension: "3/8 x 5/8",
			cartridge: 6.6,
			sausage: 13.2,
		},
		{
			dimension: "3/8 x 3/4",
			cartridge: 5.5,
			sausage: 11.2,
		},
		{
			dimension: "3/8 x 1",
			cartridge: 4.1,
			sausage: 8,
		},
		{
			dimension: "3/8 x 1.5",
			cartridge: 2.7,
			sausage: 5.4,
		},
		{
			dimension: "3/8 x 2",
			cartridge: 2,
			sausage: 4,
		},
		{
			dimension: "3/8 x 3",
			cartridge: 1.4,
			sausage: 2.6,
		},
		{
			dimension: "1/2 x 1/2",
			cartridge: 6.2,
			sausage: 12,
		},
		{
			dimension: "1/2 x 5/8",
			cartridge: 5,
			sausage: 9.8,
		},
		{
			dimension: "1/2 x 3/4",
			cartridge: 4.1,
			sausage: 8.1,
		},
		{
			dimension: "1/2 x 1",
			cartridge: 3.1,
			sausage: 6.2,
		},
		{
			dimension: "1/2 x 1.5",
			cartridge: 2,
			sausage: 4,
		},
		{
			dimension: "1/2 x 2",
			cartridge: 1.5,
			sausage: 3,
		},
		{
			dimension: "1/2 x 3",
			cartridge: 1,
			sausage: 1.9,
		},
	],

	COUNTRIES: [
		{
			id: "CAN",
			name: "Canada",
			states: [
				{ id: "AB", name: "Alberta" },
				{ id: "BC", name: "British Columbia" },
				{ id: "MB", name: "Manitoba" },
				{ id: "NB", name: "New Brunswick" },
				{ id: "NL", name: "Newfoundland and Labrador" },
				{ id: "NS", name: "Nova Scotia" },
				{ id: "NT", name: "Northwest Territories" },
				{ id: "NU", name: "Nunavut" },
				{ id: "ON", name: "Ontario" },
				{ id: "PE", name: "Prince Edward Island" },
				{ id: "QC", name: "Quebec" },
				{ id: "SK", name: "Saskachewan" },
				{ id: "YT", name: "Yukon" },
			],
		},
		{
			id: "USA",
			name: "United States",
			states: [
				{ id: "AK", name: "Alaska" },
				{ id: "AL", name: "Alabama" },
				{ id: "AR", name: "Arkansas" },
				{ id: "AZ", name: "Arizona" },
				{ id: "CA", name: "California" },
				{ id: "CO", name: "Colorado" },
				{ id: "CT", name: "Connecticut" },
				{ id: "DC", name: "DistrictofColumbia" },
				{ id: "DE", name: "Delaware" },
				{ id: "FL", name: "Florida" },
				{ id: "GA", name: "Georgia" },
				{ id: "HI", name: "Hawaii" },
				{ id: "IA", name: "Iowa" },
				{ id: "ID", name: "Idaho" },
				{ id: "IL", name: "Illinois" },
				{ id: "IN", name: "Indiana" },
				{ id: "KS", name: "Kansas" },
				{ id: "KY", name: "Kentucky" },
				{ id: "LA", name: "Louisiana" },
				{ id: "MA", name: "Massachusetts" },
				{ id: "MD", name: "Maryland" },
				{ id: "ME", name: "Maine" },
				{ id: "MI", name: "Michigan" },
				{ id: "MN", name: "Minnesota" },
				{ id: "MO", name: "Missouri" },
				{ id: "MS", name: "Mississippi" },
				{ id: "MT", name: "Montana" },
				{ id: "NC", name: "NorthCarolina" },
				{ id: "ND", name: "NorthDakota" },
				{ id: "NE", name: "Nebraska" },
				{ id: "NH", name: "NewHampshire" },
				{ id: "NJ", name: "NewJersey" },
				{ id: "NM", name: "NewMexico" },
				{ id: "NV", name: "Nevada" },
				{ id: "NY", name: "NewYork" },
				{ id: "OH", name: "Ohio" },
				{ id: "OK", name: "Oklahoma" },
				{ id: "OR", name: "Oregon" },
				{ id: "PA", name: "Pennsylvania" },
				{ id: "RI", name: "RhodeIsland" },
				{ id: "SC", name: "SouthCarolina" },
				{ id: "SD", name: "SouthDakota" },
				{ id: "TN", name: "Tennessee" },
				{ id: "TX", name: "Texas" },
				{ id: "UT", name: "Utah" },
				{ id: "VA", name: "Virginia" },
				{ id: "VT", name: "Vermont" },
				{ id: "WA", name: "Washington" },
				{ id: "WI", name: "Wisconsin" },
				{ id: "WV", name: "WestVirginia" },
				{ id: "WY", name: "Wyoming" },
			],
		},
	],
	footer_sections: [
		{
			id: "1",
			name: keys.GLOBAL.FOOTER.ADSTORE,
			elements: [
				{
					id: "1.1",
					title: keys.GLOBAL.FOOTER.ADSEAL,
					path: "/adstore?page=1&search=adseal",
					target: "_self",
				},
				{
					id: "1.2",
					title: keys.GLOBAL.FOOTER.ADBOND,
					path: "/adstore?page=1&search=adbond",
					target: "_self",
				},
				{
					id: "1.3",
					title: keys.GLOBAL.FOOTER.ADTHANE,
					path: "/adstore?page=1&search=adthane",
					target: "_self",
				},
				{
					id: "1.4",
					title: keys.GLOBAL.FOOTER.ADFOAM,
					path: "/adstore?page=1&search=adfoam",
					target: "_self",
				},
				{
					id: "1.5",
					title: keys.GLOBAL.FOOTER.THERMOBOND,
					path: "/adstore?page=1&search=thermobond",
					target: "_self",
				},
				{
					id: "1.6",
					title: keys.GLOBAL.FOOTER.ACCESSORIES,
					path: "/adstore?page=1&search=accessoires",
					target: "_self",
				},
			],
		},
		{
			id: "2",
			name: keys.GLOBAL.FOOTER.SPECIALIZED,
			elements: [
				{
					id: "2.1",
					title: keys.GLOBAL.FOOTER.CONSTRUCTION,
					path: "/construction/",
					target: "_self",
				},
				{
					id: "2.3",
					title: keys.GLOBAL.FOOTER.MANUFACTURERS,
					path: "/manufacturing/",
					target: "_self",
				},
				{
					id: "2.4",
					title: keys.GLOBAL.FOOTER.ARCHITECT,
					path: "/architect/",
					target: "_self",
				},
			],
		},
		{
			id: "3",
			name: keys.GLOBAL.FOOTER.SERVICES,
			elements: [
				{
					id: "3.1",
					title: keys.GLOBAL.FOOTER.WEBINAR,
					path: "/architect/webinar/",
					target: "_self",
				},
				{
					id: "3.2",
					title: keys.GLOBAL.FOOTER.TESTING,
					path: "/architect/visit/",
					target: "_self",
				},
				{
					id: "3.3",
					title: keys.GLOBAL.FOOTER.IN_PERSON_CONSULTATION,
					path: "/architect/visit/",
					target: "_self",
				},
				{
					id: "3.4",
					title: keys.GLOBAL.FOOTER.REMOTE_CONSULTATION,
					path: "/architect/webinar/",
					target: "_self",
				},
			],
		},
		{
			id: "4",
			name: keys.GLOBAL.FOOTER.TOOLS,
			elements: [
				{
					id: "4.1",
					title: keys.GLOBAL.FOOTER.TUTORIALS,
					path_en: "https://adfastcorp.com/en/tutorials/",
					path_fr: "https://adfastcorp.com/tutoriels/",
					target: "_blank",
				},
				{
					id: "4.2",
					title: keys.GLOBAL.FOOTER.COLOR_MATCH,
					path_en: "/tools/find-the-right-sealant/",
					path_fr: "/tools/find-the-right-sealant/",
					target: "_self",
				},
				{
					id: "4.3",
					title: keys.GLOBAL.FOOTER.SEALANT_COMP,
					path_en: "https://adfastcorp.com/en/silicone-compatibility/",
					path_fr: "https://adfastcorp.com/compatibilite-silicones/",
					target: "_blank",
				},
				{
					id: "4.4",
					title: keys.GLOBAL.FOOTER.PRODUCT_SELECTOR,
					path_en: "https://adfastcorp.com/en/guided-product-selector/",
					path_fr: "https://adfastcorp.com/product-finder/",
					target: "_blank",
				},
				{
					id: "4.5",
					title: keys.GLOBAL.FOOTER.SEALANT_CALC,
					path: "/tools/sealant-calculator/",
					target: "_self",
				},
				{
					id: "4.6",
					title: keys.GLOBAL.FOOTER.FAQ,
					path: "/faq",
					target: "_self",
				},
				{
					id: "4.7",
					title: keys.GLOBAL.FOOTER.LIBRARY,
					path_fr: "https://adfastcorp.com/bibliotheque/",
					path_en: "https://adfastcorp.com/en/library-2/",
					target: "_blank",
				},
			],
		},
		{
			id: "5",
			name: keys.GLOBAL.FOOTER.ABOUT,
			elements: [
				{
					id: "5.0",
					title: keys.GLOBAL.FOOTER.CONTACT_US,
					path: "/contact-us",
					target: "_self",
				},
				{
					id: "5.1",
					title: keys.GLOBAL.FOOTER.FIND_STORE,
					path: "/find-a-store/",
					target: "_self",
				},
				{
					id: "5.2",
					title: keys.GLOBAL.FOOTER.ADNEWS,
					path_fr: "https://adfastcorp.com/nouvelles/",
					path_en: "https://adfastcorp.com/en/news/",
					target: "_blank",
				},
				{
					id: "5.3",
					title: keys.GLOBAL.FOOTER.TEAM,
					path_fr: "https://adfastcorp.com/a-propos-2/",
					path_en: "https://adfastcorp.com/en/about-us/",
					target: "_blank",
				},
				{
					id: "5.4",
					title: keys.GLOBAL.FOOTER.CAREER,
					path_fr:
						"https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=887f8344-cc31-4562-a308-c796ba030c45&ccId=19000101_000001&lang=fr_CA",
					path_en:
						"https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=887f8344-cc31-4562-a308-c796ba030c45&ccId=19000101_000001&lang=en_CA&selectedMenuKey=CareerCenter&langChange=true",
					target: "_blank",
				},
				{
					id: "5.5",
					title: keys.GLOBAL.FOOTER.HISTORY,
					path_fr: "https://adfastcorp.com/a-propos-2/",
					path_en: "https://adfastcorp.com/en/about-us/",
					target: "_blank",
				},
				{
					id: "5.6",
					title: keys.GLOBAL.FOOTER.PARTNERS,
					path_fr: "https://adfastcorp.com/a-propos-2/",
					path_en: "https://adfastcorp.com/en/about-us/",
					target: "_blank",
				},
				{
					id: "5.7",
					title: keys.GLOBAL.FOOTER.LAB,
					path_fr: "https://adfastcorp.com/a-propos-2/",
					path_en: "https://adfastcorp.com/en/about-us/",
					target: "_blank",
				},
				{
					id: "5.8",
					title: keys.GLOBAL.FOOTER.FACTORY,
					path_fr: "https://www.youtube.com/watch?v=O5VrZHTdt5k",
					path_en: "https://www.youtube.com/watch?v=2ITNKvfGB44",
					target: "_blank",
				},
				{
					id: "5.8",
					title: keys.GLOBAL.FOOTER.GREENGUARD,
					path_fr: "/greenguard/?lng=fr-ca",
					path_en: "/greenguard/?lng=en_CA",
					target: "_self",
				},
			],
		},
		{
			id: "6",
			name: keys.GLOBAL.FOOTER.ACCOUNT,
			elements: [
				{
					id: "6.1",
					title: keys.GLOBAL.FOOTER.ACCOUNT_MNG,
					path: "account",
					target: "_self",
				},
				{
					id: "6.2",
					title: keys.GLOBAL.FOOTER.FQA_SIGNUP,
					path_fr: "https://youtu.be/y6h8BGX1Zvw",
					path_en: "https://youtu.be/c3hW06oFV_k",
					target: "_blank",
				},
				{
					id: "6.3",
					title: keys.GLOBAL.FOOTER.ORDERS,
					path: "orders",
					target: "_self",
				},
			],
		},
	],
	construction: {
		residential: {
			pickerEn: "Residential",
			pickerFr: "Résidentielle",
			titleEn0: "Residential construction projects",
			titleFr0: "Projets de construction résidentielle",
			titleEn: "Seal your indoor and outdoor spaces!",
			titleFr: "Scellez vos espaces intérieurs et extérieurs!",
			descriptionEn:
				"This diagram allows you to better visualize the multitude of applications our products are tailored for in residential construction and renovation projects.",
			descriptionFr:
				"Ce diagramme vous permet de mieux visualiser la multitude d'applications pour lesquelles nos produits sont adaptés dans les projets de construction et de rénovation résidentielles.",
		},
		commercial: {
			pickerEn: "Commercial",
			pickerFr: "Commerciale",
			titleEn0: "Commercial construction projects",
			titleFr0: "Projets de construction commerciale",
			titleEn: "We offer high performance products specifically developed for commercial construction projects.",
			titleFr:
				"Nous offrons des produits de haute performance spécifiquement développés pour les projets de construction commerciale.",

			descriptionEn: "Click on one of the products in the diagram below for more information.",
			descriptionFr: "Cliquez sur l'un des produits dans le diagramme ci-dessous pour plus d'informations.",
		},
		specialProject: {
			pickerEn: "Curtain walls",
			pickerFr: "Murs-rideaux",
			titleEn0: "Commercial construction projects - Curtain walls",
			titleFr0: "Projets de construction commerciale - Murs-rideaux",
			titleEn:
				"We offer high performance structural sealants specially designed for 2 and 4 sided curtain wall installation.",
			titleFr:
				"Nous offrons des scellants structuraux de haute performance spécialement conçus pour l'installation de murs-rideaux à 2 et 4 faces.",
			descriptionEn: "Our products ensure building envelope insulation.",
			descriptionFr: "Nos produits assurent l'isolation de l'enveloppe du bâtiment.",
		},
	},
	footer_options: {
		privacy: keys.AUTH.SIGN_UP.TERMS,
		sitemap: keys.GLOBAL.FOOTER.OPTION_SITEMAP,
	},
	adrobot: {
		overview: {
			description_fr_1:
				"Les meilleures performances et un démarrage plus rapide de la production sont combinés dans un robot compact, flexible, efficace et à l'épreuve du temps. Les robots peuvent être programmés pour effectuer plusieurs opérations séquentielles à l'aide de divers accessoires fixés au bras du robot.\nAu cas où vous ne seriez pas encore convaincu, n'oubliez pas que les robots ne prennent pas de pause déjeuner et qu'ils se font rarement malade le lundi matin.",
			description_en_1:
				"Top performance and a faster start of production are combined in a compact, flexible, cos-efficient and future proof robot. Robots can be programmed to perform multiple sequential operations using various accessories attached to the robot arm.\nJust in case you aren’t convinced yet, don’t forget that robots don’t take lunch breaks and they rarely call in sick on Monday mornings",
			benefits: {
				displayTitle: true,
				data: [
					{ id: 1, fr: "Moins de pertes de produits", en: "Less product loss" },
					{ id: 2, fr: "Précision et qualité", en: "Precision and quality" },
					{ id: 3, fr: "Performance maximale", en: "Top performance" },
					{ id: 4, fr: "Démarrage plus rapide de la production", en: "Faster production start" },
					{ id: 5, fr: "Accélération de la production", en: "Accelerated production" },
				],
			},
		},
		advantages: [
			{
				id: 1,
				fr: {
					title: "exÉcution des tâches 24/7",
					description:
						"Éliminez les tâches répétitives de vos employés et maximisez leur potentiel sur votre ligne de production. Les Adrobots atteignent des vitesses exceptionnellement élevées, ce qui leur permet de travailler rapidement sans aucune perte de précision.",
				},
				en: {
					title: "PERFORM YOUR TASKS 24/7",
					description:
						"Eliminate repetitive tasks for your employees and maximize their potential on your production line. Adrobots reach exceptionally high speeds, allowing them to work quickly without any loss of precision",
				},
			},
			{
				id: 2,
				fr: {
					title: "COMPACT",
					description:
						"Pas besoin d'un grand espace. Fixez-le au sol, au mur ou au plafond, Adrobots s'adapte à votre ligne de production et est suffisamment agile pour atteindre les endroits les plus restreints.",
				},
				en: {
					title: "COMPACT",
					description:
						"No need for a large space. Mount it to the floor, wall, or ceiling. Adrobots adapt to your production line and are agile enough to reach the most restricted places.",
				},
			},
			{
				id: 3,
				fr: {
					title: "FLEXIBILITÉ",
					description:
						"Un robot pour de multiples applications : il suffit de changer l'accessoire sur la tête du robot pour une application complètement différente. Adrobot est compatible avec une grande variété de systèmes d’alimentation en énergie.",
				},
				en: {
					title: "FLEXIBILITY",
					description:
						"One robot for multiple applications: simply change the accessory on the robot head for a completely different application. Also, you can choose from a large variety of compatible energy supply systems.",
				},
			},
		],
		applications: [
			{ id: 1, fr: "portes et fenêtres", en: "doors and windows" },
			{ id: 2, fr: "emballage", en: "packaging" },
			{ id: 3, fr: "transport", en: "transport" },
			{ id: 4, fr: "Automobile", en: "Automotive" },
			{ id: 5, fr: "palettisation", en: "palletising" },
			{ id: 6, fr: "matelas", en: "mattress" },
			{ id: 7, fr: "panneaux sandwichs", en: "sandwich panels" },
			{ id: 8, fr: "panneaux isolants", en: "insulating panels" },
			{ id: 9, fr: "découpe/séparation", en: "cutting/separation" },
			{ id: 10, fr: "assemblage", en: "assembly" },
			{ id: 11, fr: "CVAC", en: "HVAC" },
		],
		characteristics: {
			tech: {
				payload: "20 kg",
				max_reach: "1813 mm",
				construction_type_fr: "Standard",
				construction_type_en: "Standard",
				version_env_fr: "Standard",
				version_env_en: "Standard",
				mounting_pos_fr: "Angles, plafonds, sols, murs",
				mounting_pos_en: "Angles, ceiling, floor, wall",
				class: "IP65",
				tech_data_sheet_fr: "/contact-us/",
				tech_data_sheet_en: "/contact-us/",
				tech_data_sheet_txt: keys.ADROBOT.CONTACT,
				contact_us: true,
			},
		},
	},
	graco: {
		overview: {
			description_fr_1:
				"Les pompes ADFAST sont disponibles dans différentes spécifications et conviennent aussi bien aux petits volumes qu'aux productions à haut rendement. Elles peuvent distribuer des produits de moyenne à haute viscosité et peuvent être intégrées dans des systèmes complexes de lignes de distribution.\n" +
				"En fonction de vos produits spécifiques, nous vous guiderons vers la pompe la mieux adaptée à vos besoins.\nPlusieurs paramètres doivent être observés avant de faire un choix approprié, notamment :",
			description_en_1:
				"ADFAST pumps are available in different specifications and are suitable for both small volumes and high output productions. They can dispense medium to high viscosity products and can be integrated into complex distribution line systems.\n" +
				"According to your specific products, we will guide you to the best pump for your needs.\nSeveral parameters should be observed before making an appropriate choice, including:",
			benefits: {
				displayTitle: false,
				data: [
					{ id: 1, fr: "Compatibilité chimique", en: "Chemical compatibility" },
					{ id: 2, fr: "Pression", en: "Pressure" },
					{ id: 3, fr: "Viscosité des produits", en: "Viscosity of the products" },
					{
						id: 4,
						fr: "Débit optimal pour votre ligne de production",
						en: "Optimal flow rate for your production line",
					},
					{
						id: 5,
						fr: "Type d'installation (manuelle, automatique, robotique)",
						en: "Type of installation (manual, automatic, robotic)",
					},
				],
			},
			description_fr_2:
				"En fonction de ces critères, nos ingénieurs calibreront votre pompe et vous guideront dans le choix des accessoires adaptés à vos produits.\nN'hésitez pas à contacter notre équipe d'experts techniques pour vous aider !",
			description_en_2:
				"According to these criteria, our engineers will calibrate your pump and guide you in the choice of accessories adapted to your products.\nDo not hesitate to contact our Technical Experts Team to help you!",
		},
		advantages: [
			{
				id: 1,
				fr: {
					title: "ÉCONOMISER DU TEMPS ET DE L'ARGENT",
					description:
						"Conçu pour réduire les coûts, les temps d'arrêt de la ligne et minimiser les pertes de produits pour optimiser votre production.\n" +
						"Le moteur NXT® dure jusqu'à 10 fois plus longtemps que les pompes d'ancienne de pompes.\n" +
						"La technologie avancée du moteur permet un changement rapide et rapide et sans à-coups, ce qui permet d'obtenir un taux de distribution constant et une réduction des temps d'arrêt de la ligne.\n",
				},
				en: {
					title: "SAVE TIME AND MONEY",
					description:
						"Designed to reduce costs, line downtime, and minimize product loss to optimize your production.\n" +
						"The NXT® motor lasts up to 10 times longer than older generation pumps.\n" +
						"The advanced motor technology allows for fast and smooth changeover, resulting in a consistent dispensing rate and reduced line downtime.",
				},
			},
			{
				id: 2,
				fr: {
					title: "FAIBLE TEMPS D'ENTRETIEN",
					description:
						"Un contrôle total au bout de vos doigts !\n" +
						'L\'interface intuitive "One-Touch" vous permet de contrôler totalement le flux de distribution. Améliorez productivité et le temps de fonctionnement de la pompe en personnalisant les paramètres selon vos besoins.\n' +
						"Réduisez vos temps d'arrêt sur la ligne grâce à la gestion automatique de l'inversion (doublez le volume de produit avant de changer de fût ou de seau). produit avant de changer de baril ou de seau).\n" +
						"Suivez votre produit !\n" +
						"Les pompes peuvent être équipées du système DataTrack :\n" +
						"Le système fournit des informations sur le niveau d'utilisation du produit, le débit volumique et indique la quantité de produit restante. produit restant. Il enregistre la vitesse des pompes, en cas d'emballement, le DataTrack permet un arrêt d'urgence automatique. arrêt d'urgence automatique.\n" +
						"Des capteurs optionnels et une tour avec lumière alertent l'opérateur lorsqu'il est temps de remplacer le seau ou le baril. baril.\n",
				},
				en: {
					title: "LOW MAINTENANCE TIME",
					description:
						"Total control at your fingertips!\n" +
						"The intuitive “One-Touch” interface gives you complete control of the dispensing flow. Improve productivity and pump uptime by customizing settings to your needs.\n" +
						"Reduce your downtime on the line with the automatic inversion management (double the volume of product before changing barrels or buckets).\n" +
						"Track your product!\n" +
						"The pumps can be equipped with the DataTrack system:\n" +
						"The system provides information on product usage level, volume flow rate and shows the amount of product remaining. It registers the speed of the pumps, in case of a runaway, the DataTrack allows an automatic emergency stop.\n" +
						"Optional sensors and a tower with light alert the operator when it is time to replace the bucket or barrel.",
				},
			},
		],
		applications: [
			{ id: 1, fr: "silicones", en: "silicones" },
			{ id: 2, fr: "uréthanes", en: "urethanes" },
			{ id: 3, fr: "méthacrylates", en: "methacrylates" },
			{ id: 4, fr: "Hot melts", en: "Hot melts" },
		],
		characteristics: {
			desc: [
				{
					id: 1,
					fr: "Flux continu de matériaux à plusieurs composants avec des rapports de mélange standard allant de 1:1 à 20:1.",
					en: "Continuous flow of plural component materials with standard mix ratios ranging from 1:1 to 20:1.",
				},
				{
					id: 2,
					fr: "Peut également être configuré pour des matériaux renforcés de 1% à 3%.",
					en: "Can also be configured for 1% - 3% boosted materials.",
				},
				{
					id: 3,
					fr: "Les matériaux sont mélangés et distribués au point d'application.",
					en: "Materials are mixed and dispensed at the point-of-application.",
				},
				{
					id: 4,
					fr: "Purge automatique, avertissement et contrôle du ratio.",
					en: "Automatic purge, warning and ratio monitoring.",
				},
			],
		},
	},
	jamesHardi: {
		itemId: "1940",
		brandId: "Adseal",
		action: "https://www2.adfast.store/l/534932/2024-02-02/3wkj7w",
		images: {
			banner_fr: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Header_James_Hardie.png",
			banner_en:
				"https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/Partners/Header_James_Hardie_EN.jpg",
			colorChart: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/ColorChart_Section.png",
			DigitalChart: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/JamesHardie_DigitalChart.png",
		},
		pdf: {
			chart_url_en:
				"https://adfastcorpcdnak.azureedge.net/b2b/technical_document_en/James_Hardie_Color_chart.pdf",
			chart_url_fr:
				"https://adfastcorpcdnak.azureedge.net/b2b/technical_document_en/James_Hardie_Color_chart.pdf",
		},
		sections: [
			{
				title: "THE STATEMENT COLLECTION®",
				colors: [
					{
						id: "3",
						title: "194-98",
						hex: "#48515b",
						subtitle_fr: "DEEP OCEAN",
						subtitle_en: "DEEP OCEAN",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/194-98_Deep_Ocean.jpg",
					},
					{
						id: "4",
						title: "194-99",
						hex: "#bab9ba",
						subtitle_fr: "LIGHT MIST",
						subtitle_en: "LIGHT MIST",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Light_Mist_194-99.png",
					},
					{
						id: "7",
						title: "194-100",
						hex: "#747568",
						subtitle_fr: "MOUNTAIN SAGE",
						subtitle_en: "MOUNTAIN SAGE",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Mountain_Sage_194-100.png",
					},
					{
						id: "5",
						title: "194-101",
						hex: "#b4b1ad",
						subtitle_fr: "PEARL GRAY",
						subtitle_en: "PEARL GRAY",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Pearl_Gray_194-101.png",
					},
					{
						id: "8",
						title: "194-102",
						hex: "#5e686d",
						subtitle_fr: "EVENING BLUE",
						subtitle_en: "EVENING BLUE",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Evening_Blue_194-102.png",
					},
					{
						id: "10",
						title: "194-108",
						hex: "#e3e3de",
						subtitle_fr: "ARCTIC WHITE",
						subtitle_en: "ARCTIC WHITE",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Artic_White_194-108.png",
					},
					{
						id: "13",
						title: "194-109",
						hex: "#704f47",
						subtitle_fr: "COUNTRYLANE RED",
						subtitle_en: "COUNTRYLANE RED",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Countrylane_Red_194-109.png",
					},
					{
						id: "12",
						title: "194-110",
						hex: "#545857",
						subtitle_fr: "IRON GRAY",
						subtitle_en: "IRON GRAY",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Iron_Gray_194-110.png",
					},
					{
						id: "16",
						title: "194-111",
						hex: "#a39380",
						subtitle_fr: "KHAKI BROWN",
						subtitle_en: "KHAKI BROWN",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Khaki_Brownn_194-111.png",
					},
					{
						id: "6",
						title: "194-112",
						hex: "#858582",
						subtitle_fr: "GRAY SLATE",
						subtitle_en: "GRAY SLATE",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Gray_Slate_194-112.png",
					},
					{
						id: "24",
						title: "194-113",
						hex: "#beb29c",
						subtitle_fr: "Sandstone Beige",
						subtitle_en: "Sandstone Beige",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/194-113_Sandstone_Beige.jpg",
					},
					{
						id: "2",
						title: "194-114",
						hex: "#5e5954",
						subtitle_fr: "RICH ESPRESSO",
						subtitle_en: "RICH ESPRESSO",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Rich_Espresso_194-114.png",
					},
					{
						id: "9",
						title: "194-115",
						hex: "#788184",
						subtitle_fr: "BOOTHBAY BLUE",
						subtitle_en: "BOOTHBAY BLUE",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Boothbay_Blue_194-115.png",
					},
					{
						id: "22",
						title: "194-116",
						hex: "#c1ab91",
						subtitle_fr: "Autumn Tan",
						subtitle_en: "Autumn Tan",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/194-116_Autumn_Tan.jpg",
					},
					{
						id: "20",
						title: "194-117",
						hex: "#a29d82",
						subtitle_fr: "Heathered Moss",
						subtitle_en: "Heathered Moss",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/194-117_Heathered_Moss.jpg",
					},
					{
						id: "25",
						title: "194-118",
						hex: "#e6dbc5",
						subtitle_fr: "Sail Cloth",
						subtitle_en: "Sail Cloth",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/194-118_Sail_Cloth.jpg",
					},
					{
						id: "21",
						title: "194-119",
						hex: "#e7d0aa",
						subtitle_fr: "Woodland Cream",
						subtitle_en: "Woodland Cream",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/194-119_Woodland_Cream.jpg",
					},
					{
						id: "23",
						title: "194-120",
						hex: "#918270",
						subtitle_fr: "Wodstock Brown",
						subtitle_en: "Wodstock Brown",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/194-120_Woodstock_Brown.jpg",
					},
					{
						id: "1",
						title: "194-122",
						hex: "#7a7773",
						subtitle_fr: "AGED PEWTER",
						subtitle_en: "AGED PEWTER",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Aged_Pewter_194-122.png",
					},
					{
						id: "18",
						title: "194-123",
						hex: "#6f5c4a",
						subtitle_fr: "Chestnut Brown",
						subtitle_en: "Chestnut Brown",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/194-123_Chestnut_Brown.jpg",
					},
					{
						id: "15",
						title: "194-124",
						hex: "#c6beb2",
						subtitle_fr: "COBBLE STONE",
						subtitle_en: "COBBLE STONE",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Cobble_Stone_194-124.png",
					},
					{
						id: "11",
						title: "194-125",
						hex: "#a29a8a",
						subtitle_fr: "MONTEREY TAUPE",
						subtitle_en: "MONTEREY TAUPE",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Monterey_Taupe_194-125.png",
					},
					{
						id: "17",
						title: "194-126",
						hex: "#d4c9b4",
						subtitle_fr: "NAVAJO BEIGE",
						subtitle_en: "NAVAJO BEIGE",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Navajo_Beige_194-126.png",
					},
					{
						id: "19",
						title: "194-127",
						hex: "#686b6d",
						subtitle_fr: "NIGHT GRAY",
						subtitle_en: "NIGHT GRAY",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/194-127_Night_Gray.jpg",
					},
					{
						id: "14",
						title: "194-129",
						hex: "#7b6f64",
						subtitle_fr: "TIMBER BARK",
						subtitle_en: "TIMBER BARK",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Timber_Bark_194-129.png",
					},
				],
			},
		],
	},
	nichiha: {
		itemId: "4580",
		brandId: "Adseal",
		action: "https://www2.adfast.store/l/534932/2024-02-09/3wlv9k",
		images: {
			banner_fr:
				"https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/Partners/Entete_Nichiha_page_1585%20x%20480.jpg",
			banner_en:
				"https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/Partners/Header_Nichiha_page_1585%20x%20480.jpg",
			colorChart: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/ColorChart_Section.png",
			DigitalChart: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Nichiha_Color_Chart_Section.png",
		},
		pdf: {
			chart_url_en:
				"https://adfastcorpcdnak.azureedge.net/b2b/technical_document_en/Nichiha%20Digital%20Color%20Chart.pdf",
			chart_url_fr:
				"https://adfastcorpcdnak.azureedge.net/b2b/technical_document_en/Nichiha%20Digital%20Color%20Chart.pdf",
		},
		sections: [
			{
				title: "ARCHITECTURALBLOCK SERIES",
				colors: [
					{
						id: "1",
						title: "458-48",
						hex: "",
						subtitle_fr: "Gray",
						subtitle_en: "Gray",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-48_ArchitecturalBlock-Gray.jpg",
					},
					{
						id: "2",
						title: "458-23",
						hex: "",
						subtitle_fr: "Mocha",
						subtitle_en: "Mocha",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-23_ArchitecturalBlock-Mocha.jpg",
					},
					{
						id: "3",
						title: "458-17",
						hex: "",
						subtitle_fr: "Tuscan",
						subtitle_en: "Tuscan",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-17_ArchitecturalBlock-Tuscan.jpg",
					},
				],
			},
			{
				title: "CANYONBRICK",
				colors: [
					{
						id: "1",
						title: "458-34",
						hex: "",
						subtitle_fr: "SHALE BROWN",
						subtitle_en: "SHALE BROWN",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-34_CanyonBrick-ShaleBrown.jpg",
					},
				],
			},
			{
				title: "CORBOSA",
				colors: [
					{
						id: "1",
						title: "458-251",
						hex: "#3b3d3f",
						subtitle_fr: "Shadow",
						subtitle_en: "Shadow",
						image: "",
					},
				],
			},
			{
				title: "EMPIREBLOCK",
				colors: [
					{
						id: "1",
						title: "458-67",
						hex: "#c7c9c7",
						subtitle_fr: "Gray",
						subtitle_en: "Gray",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-67_EmpireBlock-Concrete.jpg",
					},
				],
			},
			{
				title: "ILLUMINATION",
				colors: [
					{
						id: "1",
						title: "458-67",
						hex: "#c7c9c7",
						subtitle_fr: "Fog",
						subtitle_en: "Fog",
						image: "",
					},
					{
						id: "2",
						title: "458-271",
						hex: "#8a2b2a",
						subtitle_fr: "Scarlett",
						subtitle_en: "Scarlett",
						image: "",
					},
					{
						id: "3",
						title: "458-250",
						hex: "#a82b2d",
						subtitle_fr: "Lava",
						subtitle_en: "Lava",
						image: "",
					},
					{
						id: "4",
						title: "458-268",
						hex: "#46494a",
						subtitle_fr: "Graphite",
						subtitle_en: "Graphite",
						image: "",
					},
					{
						id: "5",
						title: "458-270",
						hex: "#d3d4ce",
						subtitle_fr: "Warm White",
						subtitle_en: "Warm White",
						image: "",
					},
					{
						id: "6",
						title: "4585",
						hex: "#e3e7e4",
						subtitle_fr: "Cotton",
						subtitle_en: "Cotton",
						image: "",
					},
					{
						id: "7",
						title: "458-267",
						hex: "#675643",
						subtitle_fr: "Russet",
						subtitle_en: "Russet",
						image: "",
					},
					{
						id: "8",
						title: "458-87",
						hex: "#262528",
						subtitle_fr: "Raven",
						subtitle_en: "Raven",
						image: "",
					},
					{
						id: "9",
						title: "458-272",
						hex: "#1a6681",
						subtitle_fr: "Bay Blue",
						subtitle_en: "Bay Blue",
						image: "",
					},
					{
						id: "10",
						title: "458-115",
						hex: "#656966",
						subtitle_fr: "Nickel",
						subtitle_en: "Nickel",
						image: "",
					},
					{
						id: "11",
						title: "458-248",
						hex: "",
						subtitle_fr: "Totally Tan",
						subtitle_en: "Totally Tan",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-248_Illumination-Totally%20Tan.jpg",
					},
					{
						id: "12",
						title: "458-247",
						hex: "",
						subtitle_fr: "Toasty",
						subtitle_en: "Toasty",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-247_Illumination-Toasty.jpg",
					},
				],
			},
			{
				title: "INDUSTRIAL BLOCK",
				colors: [
					{
						id: "1",
						title: "458-67",
						hex: "#c7c9c7",
						subtitle_fr: "Concrete",
						subtitle_en: "Concrete",
						image: "",
					},
				],
			},
			{
				title: "LATURA V GROOVE",
				colors: [
					{
						id: "1",
						title: "458-34",
						hex: "#d7d2cb",
						subtitle_fr: "Gray",
						subtitle_en: "Gray",
						image: "",
					},
					{
						id: "2",
						title: "458-143",
						hex: "#e0ded9",
						subtitle_fr: "White",
						subtitle_en: "White",
						image: "",
					},
				],
			},
			{
				title: "MIRAIA SERIES",
				colors: [
					{
						id: "1",
						title: "4588",
						hex: "",
						subtitle_fr: "Glacier",
						subtitle_en: "Glacier",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/4588-Miraia-Glacier.jpg",
					},
					{
						id: "2",
						title: "458-902",
						hex: "#1d2525",
						subtitle_fr: "Onyx",
						subtitle_en: "Onyx",
						image: "",
					},
					{
						id: "3",
						title: "458-92",
						hex: "#d0cfca",
						subtitle_fr: "Snow",
						subtitle_en: "Snow",
						image: "",
					},
				],
			},
			{
				title: "MODERN BRICK",
				colors: [
					{
						id: "1",
						title: "458-251",
						hex: "",
						subtitle_fr: "Midnight Brick",
						subtitle_en: "Midnight Brick",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-251_ModernBrick-Midnight.jpg",
					},
					{
						id: "2",
						title: "458-224",
						hex: "#6f695e",
						subtitle_fr: "Midnight Grout",
						subtitle_en: "Midnight Grout",
						image: "",
					},
				],
			},
			{
				title: "NATURA",
				colors: [
					{
						id: "1",
						title: "458-152",
						hex: "#a99e8c",
						subtitle_fr: "Rustic White",
						subtitle_en: "Rustic White",
						image: "",
					},
				],
			},
			{
				title: "NOVENARY TILE SERIES",
				colors: [
					{
						id: "1",
						title: "458-192",
						hex: "",
						subtitle_fr: "Clay",
						subtitle_en: "Clay",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-192_NovenaryTile-Clay.jpg",
					},
					{
						id: "2",
						title: "458-191",
						hex: "",
						subtitle_fr: "Ochre",
						subtitle_en: "Ochre",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-191_Novenarytile-Ochre.jpg",
					},
					{
						id: "3",
						title: "458-92",
						hex: "",
						subtitle_fr: "OPAL",
						subtitle_en: "OPAL",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-92_NovenaryTile-Opal.jpg",
					},
					{
						id: "4",
						title: "458-193",
						hex: "",
						subtitle_fr: "Sand",
						subtitle_en: "Sand",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-193_NovenaryTile-Sand.jpg",
					},
					{
						id: "5",
						title: "458-188",
						hex: "",
						subtitle_fr: "Slate",
						subtitle_en: "Slate",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-188_NovenaryTile-Slate.jpg",
					},
				],
			},
			{
				title: "PLYMOUTHBRICK SERIES",
				colors: [
					{
						id: "1",
						title: "458-33",
						hex: "",
						subtitle_fr: "Crimson Groot",
						subtitle_en: "Crimson Groot",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-33_Plymouth%20Brick-Crimson.jpg",
					},
					{
						id: "2",
						title: "458-233",
						hex: "",
						subtitle_fr: "Crimson Brick",
						subtitle_en: "Crimson Brick",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-233_PlymouthBrick-Crimson.jpg",
					},
				],
			},
			{
				title: "RIBBED PANEL",
				colors: [
					{
						id: "1",
						title: "458-189",
						hex: "#48515b",
						subtitle_fr: "Indigo",
						subtitle_en: "Indigo",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-189_RibbedPanel-Indigo.jpg",
					},
					{
						id: "2",
						title: "458-92",
						hex: "",
						subtitle_fr: "Ivory",
						subtitle_en: "Ivory",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-92_Ribbed-Panel-Ivory.jpg",
					},
				],
			},
			{
				title: "RIFTSAWN SERIES",
				colors: [
					{
						id: "1",
						title: "458-21",
						hex: "#575551",
						subtitle_fr: "Chestnut",
						subtitle_en: "Chestnut",
						image: "",
					},
					{
						id: "2",
						title: "458-21",
						hex: "#575551",
						subtitle_fr: "Pecan",
						subtitle_en: "Pecan",
						image: "",
					},
				],
			},
			{
				title: "ROUGHSAWN SERIES",
				colors: [
					{
						id: "1",
						title: "458-47",
						hex: "#48515b",
						subtitle_fr: "Espresso",
						subtitle_en: "Espresso",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-47_RoughSawn-Espresso.jpg",
					},
					{
						id: "2",
						title: "458-44",
						hex: "#bab9ba",
						subtitle_fr: "Smoke",
						subtitle_en: "Smoke",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-44_RoughSawn-Smoke.jpg",
					},
					{
						id: "3",
						title: "458-168",
						hex: "",
						subtitle_fr: "Tobacco",
						subtitle_en: "Tobacco",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-168_RoughSawn-Tobacco.jpg",
					},
				],
			},
			{
				title: "SANDSTONE SERIES",
				colors: [
					{
						id: "1",
						title: "458-48",
						hex: "",
						subtitle_fr: "Autumn Brown",
						subtitle_en: "Autumn Brown",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-48_SandStonell-AutumnBrown.jpg",
					},
					{
						id: "2",
						title: "458-15",
						hex: "#d7c4b7",
						subtitle_fr: "Desert Beige",
						subtitle_en: "Desert Beige",
						image: "",
					},
					{
						id: "3",
						title: "458-44",
						hex: "",
						subtitle_fr: "Gentle Gray",
						subtitle_en: "Gentle Gray",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-44_SandStone-gentleGray.jpg",
					},
				],
			},
			{
				title: "SIERRA PREMIUM SHAKE",
				colors: [
					{
						id: "1",
						title: "458-233",
						hex: "#835c43",
						subtitle_fr: "Mahogany",
						subtitle_en: "Mahogany",
						image: "",
					},
					{
						id: "2",
						title: "458-234",
						hex: "",
						subtitle_fr: "Smoke",
						subtitle_en: "Smoke",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-234_SierraPremiumShake-Smoke.jpg",
					},
					{
						id: "3",
						title: "458-235",
						hex: "",
						subtitle_fr: "Charcoal",
						subtitle_en: "Charcoal",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-235_SierraPremiumShake-Charcoal.jpg",
					},
					{
						id: "4",
						title: "458-236",
						hex: "",
						subtitle_fr: "Maple",
						subtitle_en: "Maple",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-236_SierraPremiumShake-Mapple.jpg",
					},
				],
			},
			{
				title: "KURASTONE",
				colors: [
					{
						id: "1",
						title: "458-41",
						hex: "#d3bba8",
						subtitle_fr: "StackedStone-Desert",
						subtitle_en: "StackedStone-Desert",
						image: "",
					},
					{
						id: "2",
						hex: "#afa9a0",
						title: "458-32",
						subtitle_fr: "StackedStone-Mountain",
						subtitle_en: "StackedStone-Mountain",
						image: "",
					},
					{
						id: "3",
						title: "458-48",
						hex: "#c4bfb6",
						subtitle_fr: "LedgeStone-Bluff",
						subtitle_en: "LedgeStone-Bluff",
						image: "",
					},
				],
			},
			{
				title: "TUFFBLOCK SERIES",
				colors: [
					{
						id: "1",
						title: "458-126",
						hex: "",
						subtitle_fr: "Bamboo",
						subtitle_en: "Bamboo",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-126_TuffBlock-Bamboo.jpg",
					},
					{
						id: "2",
						title: "458-48",
						hex: "",
						subtitle_fr: "Pewter",
						subtitle_en: "Pewter",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-48_TuffBlock-Pewter.jpg",
					},
					{
						id: "3",
						title: "458-75",
						hex: "",
						subtitle_fr: "Steel",
						subtitle_en: "Steel",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-75_TuffBlock-Steel.jpg",
					},
					{
						id: "4",
						title: "458-23",
						hex: "",
						subtitle_fr: "Walnut",
						subtitle_en: "Walnut",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-23_TuffBlock-Walnut.jpg",
					},
				],
			},
			{
				title: "VINTAGEBRICK SERIES",
				colors: [
					{
						id: "1",
						title: "458-54",
						hex: "",
						subtitle_fr: "Alexandria Buff Brick ",
						subtitle_en: "Alexandria Buff Brick ",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-54_VintageBrick-AlexandriaBuff.jpg",
					},
					{
						id: "2",
						title: "458-62",
						hex: "",
						subtitle_fr: "White Wash Grout",
						subtitle_en: "White Wash Grout",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-62_VintageBrick-WhiteWash.jpg",
					},
					{
						id: "3",
						title: "458-72",
						hex: "",
						subtitle_fr: "White Wash Brick",
						subtitle_en: "White Wash Brick",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-72_VintageBrick-Wash.jpg",
					},
					{
						id: "4",
						title: "458-106",
						hex: "#9e9382",
						subtitle_fr: "Alexandria Buff Grout",
						subtitle_en: "Alexandria Buff Grout",
						image: "",
					},
					{
						id: "5",
						title: "4582",
						hex: "",
						subtitle_fr: "White Smoke",
						subtitle_en: "White Smoke",
						image: "https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/Partners/4582_VintageBrick-WhiteSmoke.jpg",
					},
				],
			},
			{
				title: "VINTAGEWOOD SERIES",
				colors: [
					{
						id: "1",
						title: "458-14",
						hex: "",
						subtitle_fr: " Poplar",
						subtitle_en: " Poplar",
						image: " https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/Partners/458-14_VintageWoodPoplar.jpg",
					},
					{
						id: "2",
						title: "458-48",
						hex: "",
						subtitle_fr: "Ash",
						subtitle_en: "Ash",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-48_VintageWood-Ash.jpg",
					},
					{
						id: "3",
						title: "458-57",
						hex: "",
						subtitle_fr: "Redwood",
						subtitle_en: "Redwood",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-47vintageWood-RedWood.jpg",
					},
					{
						id: "4",
						title: "458-63",
						hex: "",
						subtitle_fr: "Cedar",
						subtitle_en: "Cedar",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-63_VintageWood-Cedar.jpg",
					},
					{
						id: "5",
						title: "458-65",
						hex: "",
						subtitle_fr: "Spruce",
						subtitle_en: "Spruce",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-65_VintageWood-Spruce.jpg",
					},
					{
						id: "6",
						title: "4587",
						hex: "",
						subtitle_fr: "Bark",
						subtitle_en: "Bark",
						image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/4587-VintageWood-Bark.jpg",
					},
				],
			},
		],
	},
	ceraclad: {
		itemId: "4580",
		brandId: "Adseal",
		action: "https://www2.adfast.store/l/534932/2024-02-09/3wlv9k",
		images: {
			banner_fr: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Header_Ceraclad.png",
			banner_en: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Header_Ceraclad_EN.png",
			colorChart: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/ColorChart_Section.png",
			DigitalChart: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/Ceraclad_Color_Chart_Section.png",
		},
		pdf: {
			chart_url_en:
				"https://adfastcorpcdnak.azureedge.net/b2b/technical_document_en/Ceraclad%20color%20chart.pdf",
			chart_url_fr:
				"https://adfastcorpcdnak.azureedge.net/b2b/technical_document_en/Ceraclad%20color%20chart.pdf",
		},
		sections: [
			{
				title: "STANDARD DESIGN",
				sectionColors: [
					{
						title: "Cashmere Smooth | Zen Garden | 8-reveal",
						colors: [
							{
								id: "1",
								title: "458-17",
								hex: "#8c7c67",
								subtitle_fr: "Brass",
								subtitle_en: "Brass",
								image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-17_CashmereSmooth_Brass.jpg",
							},
							{
								id: "2",
								title: "458-22",
								hex: "#474948",
								subtitle_fr: "Charcoal",
								subtitle_en: "Charcoal",
								image: "",
							},
							{
								id: "3",
								title: "458-29",
								hex: "#ded6ca",
								subtitle_fr: "Silk",
								subtitle_en: "Silk",
								image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-29_CashmereSmooth_Silk.jpg",
							},
							{
								id: "4",
								title: "458-29",
								hex: "#ded6ca",
								subtitle_fr: "Pearl",
								subtitle_en: "Pearl",
								image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-29_CashmereSmooth_Pearl.jpg",
							},
							{
								id: "5",
								title: "458-71",
								hex: "#976240",
								subtitle_fr: "Daisy Orange",
								subtitle_en: "Daisy Orange",
								image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-71_CashmereSmooth_DaisyOrange.jpg",
							},
							{
								id: "6",
								title: "458-115",
								hex: "#65696a",
								subtitle_fr: "Slate",
								subtitle_en: "Slate",
								image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-115_CashmereSmooth_Slate.jpg",
							},
							{
								id: "7",
								title: "458-140",
								hex: "#95533d",
								subtitle_fr: "Saffron",
								subtitle_en: "Saffron",
								image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-140_CashmereSmooth_Saffron.jpg",
							},
							{
								id: "8",
								title: "458-319",
								hex: "#474a53",
								subtitle_fr: "Midnight blue",
								subtitle_en: "Midnight blue",
								image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-319_CashmereSmooth_MidNightBlue.jpg",
							},
							{
								id: "9",
								title: "458-336",
								hex: "#725859",
								subtitle_fr: "Burgundy",
								subtitle_en: "Burgundy",
								image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-336_CashmereSmooth_Burgundy.jpg",
							},
							{
								id: "10",
								title: "458-337",
								hex: "#6c6d6b",
								subtitle_fr: "Dusky Gray",
								subtitle_en: "Dusky Gray",
								image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-337_CashmereSmooth_DuskyGray.jpg",
							},
							{
								id: "11",
								title: "458-353",
								hex: "#dad9d8",
								subtitle_fr: "Artic White",
								subtitle_en: "Artic White",
								image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-353_CashmereSmooth_ArcticWhite.jpg",
							},
							{
								id: "12",
								title: "458-354",
								hex: "#696864",
								subtitle_fr: "Dark Olive",
								subtitle_en: "Dark Olive",
								image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-354_CashmereSmooth_DarkOlive.jpg",
							},
							{
								id: "13",
								title: "458-355",
								hex: "#8d8b87",
								subtitle_fr: "Mid Gray",
								subtitle_en: "Mid Gray",
								image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-355_CashmereSmooth_MidGray.jpg",
							},
							{
								id: "14",
								title: "458-356",
								hex: "#b5b4af",
								subtitle_fr: "Silver Gray",
								subtitle_en: "Silver Gray",
								image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-356_CashmereSmooth_SilverGray.jpg",
							},
							{
								id: "15",
								title: "458-357",
								hex: "#c3ab8f",
								subtitle_fr: "Wheat",
								subtitle_en: "Wheat",
								image: "https://adfastcorpcdnak.azureedge.net/b2b/Partners/458-357_CashmereSmooth_Wheat.jpg",
							},
						],
					},
				],
			},
			{
				title: "CONCRETE SERIES",
				sectionColors: [
					{
						title: "Board Formed Concrete",
						colors: [
							{
								id: "1",
								title: "4589",
								hex: "#a5a2a0",
								subtitle_fr: "Concrete Gray",
								subtitle_en: "Concrete Gray",
								image: "",
							},
							{
								id: "2",
								title: "458-44",
								hex: "#908a82",
								subtitle_fr: "Warm Gray",
								subtitle_en: "Warm Gray",
								image: "",
							},
							{
								id: "3",
								title: "458-119",
								hex: "#7a7674",
								subtitle_fr: " Fog Gray",
								subtitle_en: " Fog Gray",
								image: "",
							},
						],
					},
					{
						title: "Cast Stripe",
						colors: [
							{
								id: "1",
								title: "4588",
								hex: "#b7b8b8",
								subtitle_fr: "Ash",
								subtitle_en: "Ash",
								image: "",
							},
						],
					},
					{
						title: "Elements",
						colors: [
							{
								id: "1",
								title: "4588",
								hex: "#b7b8b8",
								subtitle_fr: "Ashlar Slate",
								subtitle_en: "Ashlar Slate",
								image: "",
							},
							{
								id: "2",
								title: "458-119",
								hex: "#7a7675",
								subtitle_fr: "Weathered",
								subtitle_en: "Weathered",
								image: "",
							},
						],
					},
				],
			},
			{
				title: "WOOD SERIES",
				sectionColors: [
					{
						title: "Barnwood",
						colors: [
							{
								id: "1",
								title: "458-278",
								hex: "#adaca8",
								subtitle_fr: "Ash",
								subtitle_en: "Ash",
								image: "",
							},
							{
								id: "2",
								title: "458-327",
								hex: "#7d7a76",
								subtitle_fr: " Black",
								subtitle_en: " Black",
								image: "",
							},
							{
								id: "3",
								title: "458-327",
								hex: "#7d7a76",
								subtitle_fr: "Brown",
								subtitle_en: "Brown",
								image: "",
							},
							{
								id: "4",
								title: "458-329",
								hex: "#a88e78",
								subtitle_fr: "Mahogany",
								subtitle_en: "Mahogany",
								image: "",
							},
						],
					},
					{
						title: "Rustic Wood",
						colors: [
							{
								id: "1",
								title: "458-21",
								hex: "#565854",
								subtitle_fr: "Coffee",
								subtitle_en: "Coffee",
								image: "",
							},
							{
								id: "2",
								title: "458-22",
								hex: "#474948",
								subtitle_fr: "Charcoal",
								subtitle_en: "Charcoal",
								image: "",
							},
							{
								id: "3",
								title: "458-48",
								hex: "#969288",
								subtitle_fr: "Costal Gray",
								subtitle_en: "Costal Gray",
								image: "",
							},
							{
								id: "4",
								title: "458-86",
								hex: "#755a48",
								subtitle_fr: "Almond",
								subtitle_en: "Almond",
								image: "",
							},
							{
								id: "5",
								title: "458-358",
								hex: "#877161",
								subtitle_fr: "Caramel",
								subtitle_en: "Caramel",
								image: "",
							},
						],
					},
					{
						title: "Urban Cedar",
						colors: [
							{
								id: "1",
								title: "458-44",
								hex: "#908a81",
								subtitle_fr: "Ash",
								subtitle_en: "Ash",
								image: "",
							},
							{
								id: "2",
								title: "458-65",
								hex: "#957e6c",
								subtitle_fr: "Cappuccino",
								subtitle_en: "Cappuccino",
								image: "",
							},
							{
								id: "3",
								title: "458-243",
								hex: "#544b42",
								subtitle_fr: "Espresso",
								subtitle_en: "Espresso",
								image: "",
							},
							{
								id: "4",
								title: "458-358",
								hex: "#877161",
								subtitle_fr: "Golden Brown",
								subtitle_en: "Golden Brown",
								image: "",
							},
							{
								id: "5",
								title: "458-359",
								hex: "#92735f",
								subtitle_fr: "Honey",
								subtitle_en: "Honey",
								image: "",
							},
						],
					},
				],
			},
		],
	},
	colorMatch: {
		color_code_info_image:
			"https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/Product_Pictures/Bar-Code.jpg",
		matchTypeDetails: {
			1: {
				label_en: "Perfect Match",
				label_fr: "Agencement parfait",
				backgroundColor: "#A9D8B0",
				border: "1px solid #217239",
				color: "#217239",
			},
			2: {
				label_en: "Good Match",
				label_fr: "Agencement Bon",
				backgroundColor: "#FFE5B3",
				border: "1px solid #CC8800",
				color: "#CC8800",
			},
			3: {
				label_en: "Close Match",
				label_fr: "Agencement proche",
				backgroundColor: "#80BCFF",
				border: ".2px solid #007AFF",
				color: "#007AFF",
			},
			4: {
				label_en: "Multitone",
				label_fr: "Multi-tons",
				backgroundColor: "#ffffff",
				border: "1px solid black",
				color: "black",
			},
			5: {
				label_en: "Metallic",
				label_fr: "Métallique",
				backgroundColor: "#C0C0C0",
				border: "1px solid black",
				color: "black",
			},
			0: {
				label_en: "",
				label_fr: "",
				backgroundColor: "",
			},
		},
		products: {
			1940: "#8EC22C",
			4550: "#173E7C",
			4580: "#127BAC",
			4600: "#4A0303",
			4800: "#1492A1",
		},
	},
};
