import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";

import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

import { isLoggedInSelector, logout } from "../../../store/auth";
import { setCartOpen } from "../../../store/cart";

import { keys } from "../../../locales/localeskeys";

import classes from "./NavOverlay.module.scss";
import { clearAll } from "../../../store/productQuery";
import { useLocation, useNavigate } from "react-router-dom";

const NavOverlay = (props) => {
	const classes = props.classes;
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const search = `?lng=${lngId}`;

	const isLoggedIn = useSelector(isLoggedInSelector);

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	useEffect(() => {
		if (props.onOpen === "open") {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "";
		}
	}, [props.onOpen]);

	const onLogout = () => {
		dispatch(logout());
	};

	const onLocaliseChange = (lng) => {
		i18n.changeLanguage(lng);
	};

	const parentOptions = [
		{ id: "10", title: t(keys.PRODUCTS.INDUSTRIES) },
		{ id: "11", title: t(keys.GLOBAL.NAVBAR.PRODUCTS), linkTo: `/adstore/${search}`, target: "_self" },
		{ id: "12", title: t(keys.GLOBAL.NAVBAR.TOOLS) },
		{ id: "32", title: t(keys.GLOBAL.NAVBAR.AUTOMATION) },
		{ id: "36", title: t(keys.GLOBAL.NAVBAR.PARTNERS) },
		{ id: "15", title: t(keys.GLOBAL.COMMON.LANGUAGE) },
		{ id: "16", title: t(keys.GLOBAL.FOOTER.ACCOUNT_MNG), linkTo: `/sign-in${search}`, target: "_self" },
	];
	const childOptions = [
		{
			id: "17",
			parentId: "10",
			title: t(keys.GLOBAL.FOOTER.CONSTRUCTION),
			linkTo: `/construction${search}`,
			target: "_self",
		},
		{
			id: "18",
			parentId: "10",
			title: t(keys.GLOBAL.FOOTER.MANUFACTURERS),
			linkTo: `/manufacturing${search}`,
			target: "_self",
		},
		{
			id: "19",
			parentId: "10",
			title: t(keys.GLOBAL.FOOTER.ARCHITECT),
			linkTo: `/architect${search}`,
			target: "_self",
		},
		{
			id: "20",
			parentId: "12",
			title: t(keys.GLOBAL.NAVBAR.PRODUCT_COMPATIBILITY),
			linkTo: lngId.includes("en")
				? "https://adfastcorp.com/en/silicone-compatibility/"
				: "https://adfastcorp.com/compatibilite-silicones/",
			target: "_blank",
		},
		{
			id: "21",
			parentId: "12",
			title: t(keys.GLOBAL.NAVBAR.COLOR_TOOL),
			linkTo: `/tools/find-the-right-sealant/${search}`,
			target: "_self",
		},
		{
			id: "22",
			parentId: "12",
			title: t(keys.GLOBAL.NAVBAR.GUIDED_SELECTOR),
			linkTo: lngId.includes("en")
				? "https://adfastcorp.com/en/guided-product-selector/"
				: "https://adfastcorp.com/product-finder/",
			target: "_blank",
		},
		{
			id: "35",
			parentId: "12",
			title: t(keys.GLOBAL.NAVBAR.CALCULATOR),
			linkTo: `/tools/sealant-calculator/${search}`,
			target: "_self",
		},
		{ id: "26", parentId: "15", title: "fr-ca", onClick: () => onLocaliseChange("fr-ca") },
		{ id: "27", parentId: "15", title: "en-ca", onClick: () => onLocaliseChange("en-ca") },
		{ id: "28", parentId: "15", title: "en-us", onClick: () => onLocaliseChange("en-us") },
		{
			id: "23",
			parentId: "16",
			title: isLoggedIn ? t(keys.AUTH.SIGN_IN.LOG_OUT) : t(keys.AUTH.FORGOT_PASSWORD.LOGIN),
			linkTo: isLoggedIn ? undefined : `/sign-in${search}`,
			target: "_self",
			onClick: isLoggedIn ? onLogout : undefined,
		},
		{
			id: "24",
			parentId: "16",
			title: t(keys.GLOBAL.NAVBAR.MYACCOUNT),
			linkTo: `/account${search}`,
			target: "_self",
		},
		{ id: "25", parentId: "16", title: t(keys.GLOBAL.NAVBAR.ORDERS), linkTo: `/orders${search}`, target: "_self" },
		{
			id: "35",
			parentId: "11",
			title: lngId.includes("en") ? "All products" : "Tous les produits",
			linkTo: `/adstore/${search}`,
			target: "_self",
			onClick: () => dispatch(clearAll()),
		},
		{
			id: "26",
			parentId: "11",
			title: lngId.includes("en") ? "Adseal Sealants" : "Scellants Adseal",
			linkTo: `/adstore/${search}&brands=${["Adseal"].join(",")}`,
			target: "_self",
			onClick: () => dispatch(clearAll()),
		},
		{
			id: "27",
			parentId: "11",
			title: lngId.includes("en") ? "Adbond Adhesives" : "Adhésif Adbond",
			linkTo: `/adstore/${search}&brands=${["Adbond"].join(",")}`,
			target: "_self",
			onClick: () => dispatch(clearAll()),
		},
		{
			id: "28",
			parentId: "11",
			title: lngId.includes("en") ? "Adfoam Insulating foam" : "Mousses isolantes Adfoam",
			linkTo: `/adstore/${search}&brands=${["Adfoam"].join(",")}`,
			target: "_self",
			onClick: () => dispatch(clearAll()),
		},
		{
			id: "30",
			parentId: "11",
			title: lngId.includes("en") ? "Adthane Polyurethanes" : "Polyuréthanes Adthane",
			linkTo: `/adstore/${search}&brands=${["Adthane"].join(",")}`,
			target: "_self",
			onClick: () => dispatch(clearAll()),
		},
		{
			id: "31",
			parentId: "11",
			title: lngId.includes("en") ? "Thermobond Hot-melt adhesives" : "Colles thermofusibles Thermobond",
			linkTo: `/adstore/${search}&brands=${["Thermobond"].join(",")}`,
			target: "_self",
			onClick: () => dispatch(clearAll()),
		},
		{
			id: "33",
			parentId: "32",
			title: t(keys.GLOBAL.NAVBAR.ADROBOT),
			linkTo: `/adrobot/${search}`,
			target: "_self",
		},
		{
			id: "34",
			parentId: "32",
			title: t(keys.GLOBAL.NAVBAR.DTECH),
			linkTo: `/dispensing-technologie/${search}`,
			target: "_self",
		},
		// {
		// 	id: "37",
		// 	parentId: "36",
		// 	title: t(keys.GLOBAL.NAVBAR.JAMESHARDI),
		// 	linkTo: `/jameshardie/${search}`,
		// 	target: "_self",
		// },
		{
			id: "38",
			parentId: "36",
			title: t(keys.GLOBAL.NAVBAR.NICHIHA),
			linkTo: `/nichiha/${search}`,
			target: "_self",
		},
		{
			id: "39",
			parentId: "36",
			title: t(keys.GLOBAL.NAVBAR.CERACLAD),
			linkTo: `/ceraclad/${search}`,
			target: "_self",
		},
	];

	const [selectedParentId, setSelectedParentId] = useState("");
	const [selectedParent, setSelectedParent] = useState();
	const [selectedChilds, setSelectedChilds] = useState();

	useEffect(() => {
		if (props.onOpen === "open") {
			setSelectedParentId("");
		}
	}, [props.onOpen]);

	useEffect(() => {
		if (selectedParentId === "") {
			setSelectedChilds(undefined);
			setSelectedParent(undefined);
		} else {
			setSelectedChilds(childOptions.filter((child) => child.parentId === selectedParentId));
			setSelectedParent(parentOptions.find((parent) => parent.id === selectedParentId));
		}
	}, [selectedParentId]);

	const backToParent = () => {
		setSelectedParentId("");
	};

	const onClick = (item) => {
		if (item.onClick) item.onClick(item);
		else if (props.onClick) props.onClick(item);
		props.onClose();
	};

	const protalElement = document.getElementById("overlays");

	return (
		<Fragment>
			{ReactDOM.createPortal(
				<div
					className={`${classes.backdrop} ${props.onOpen ? classes[props.onOpen] : ""}`}
					onClick={() => {
						props.onClose();
					}}
				/>,
				protalElement
			)}
			{ReactDOM.createPortal(
				<div className={`${classes.wrapper} ${props.onOpen ? classes[props.onOpen] : ""}`}>
					<div className={classes.main}>
						<div className={classes.sub}>
							{selectedParent && (
								<div className={classes.title_wrapper} onClick={() => props.onClose()}>
									<strong className={classes.title}>
										{selectedParent.linkTo && (
											<CustomNavLink
												to={selectedParent.linkTo}
												target={selectedParent.target}
												className={classes.link}
											>
												<span>{selectedParent.title}</span>
											</CustomNavLink>
										)}
										{!selectedParent.linkTo && (
											<div className={classes.link}>
												<span>{selectedParent.title}</span>
											</div>
										)}
									</strong>
								</div>
							)}
							{selectedParentId === "" && (
								<ul className={`${props.onOpen ? classes[props.onOpen] : ""}`}>
									<li
										id={"9"}
										onClick={() => {
											props.onClose();
										}}
									>
										<CustomNavLink to={"/"} className={classes.links}>
											<span>{t(keys.GLOBAL.NAVBAR.HOME)}</span>
										</CustomNavLink>
									</li>
									<li id={"10"}>
										<div
											className={classes.links}
											onClick={() => {
												setSelectedParentId("10");
											}}
										>
											<span>{t(keys.PRODUCTS.INDUSTRIES)}</span>
											<Icon className={classes.arrow} icon="ep:arrow-right" />
										</div>
									</li>
									<li id={"11"}>
										<div
											className={classes.links}
											onClick={() => {
												setSelectedParentId("11");
											}}
										>
											<span>Products</span>
											<Icon className={classes.arrow} icon="ep:arrow-right" />
										</div>
									</li>
									<li id={"12"}>
										<div
											className={classes.links}
											onClick={() => {
												setSelectedParentId("12");
											}}
										>
											<span>{t(keys.GLOBAL.NAVBAR.TOOLS)}</span>
											<Icon className={classes.arrow} icon="ep:arrow-right" />
										</div>
									</li>
									<li id={"32"}>
										<div
											className={classes.links}
											onClick={() => {
												setSelectedParentId("32");
											}}
										>
											<span>{t(keys.GLOBAL.NAVBAR.AUTOMATION)}</span>
											<Icon className={classes.arrow} icon="ep:arrow-right" />
										</div>
									</li>
									<li id={"36"}>
										<div
											className={classes.links}
											onClick={() => {
												setSelectedParentId("36");
											}}
										>
											<span>{t(keys.GLOBAL.NAVBAR.PARTNERS)}</span>
											<Icon className={classes.arrow} icon="ep:arrow-right" />
										</div>
									</li>
									<li
										onClick={() => {
											props.onClose();
										}}
									>
										<CustomNavLink to={`/architect/library/${search}`} className={classes.links}>
											<span>{t(keys.ARCHITECT.LIBRARYTXT)}</span>
										</CustomNavLink>
									</li>
									<li
										id={"13"}
										onClick={() => {
											if (isLoggedIn) {
												dispatch(setCartOpen(true));
												props.onClose();
											} else {
												navigate({ pathname: `/sign-in`, search: queryParams.toString() });
											}
										}}
									>
										<div className={classes.links}>
											<span>{t(keys.CART.OVERVIEW.TITLE)}</span>
										</div>
									</li>
									<li
										id={"14"}
										onClick={() => {
											props.onClose();
										}}
									>
										<CustomNavLink to={"/find-a-store/"} target={"_self"} className={classes.links}>
											<span>{t(keys.GLOBAL.FOOTER.FIND_STORE)}</span>
										</CustomNavLink>
									</li>
									<li id={"15"}>
										<div
											className={classes.links}
											onClick={() => {
												setSelectedParentId("15");
											}}
										>
											<span>{t(keys.GLOBAL.COMMON.LANGUAGE)}</span>
											<Icon className={classes.arrow} icon="ep:arrow-right" />
										</div>
									</li>
									<li id={"16"}>
										<div
											className={classes.links}
											onClick={() => {
												setSelectedParentId("16");
											}}
										>
											<span>{t(keys.GLOBAL.FOOTER.ACCOUNT)}</span>
											<Icon className={classes.arrow} icon="ep:arrow-right" />
										</div>
									</li>
									<li
										id={"17"}
										onClick={() => {
											props.onClose();
										}}
										className={classes.secHeader}
									>
										<CustomNavLink
											to={
												lngId.includes("fr")
													? "https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=887f8344-cc31-4562-a308-c796ba030c45&ccId=19000101_000001&lang=fr_CA"
													: "https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=887f8344-cc31-4562-a308-c796ba030c45&ccId=19000101_000001&lang=en_CA&selectedMenuKey=CareerCenter&langChange=true"
											}
											target="_blank"
											className={classes.links}
										>
											<span>{t(keys.GLOBAL.NAVBAR.JOINTEAM)}</span>
										</CustomNavLink>
									</li>
									<li
										id={"18"}
										onClick={() => {
											props.onClose();
										}}
										className={classes.secHeader}
									>
										<CustomNavLink to="/aboutus" target="_self" className={classes.links}>
											<span>{t(keys.GLOBAL.NAVBAR.ABOUT_US)}</span>
										</CustomNavLink>
									</li>
									<li
										id={"19"}
										onClick={() => {
											props.onClose();
										}}
										className={classes.secHeader}
									>
										<CustomNavLink to="/faq" target="_self" className={classes.links}>
											<span>{t(keys.GLOBAL.NAVBAR.FAQ)}</span>
										</CustomNavLink>
									</li>
									<li
										id={"20"}
										onClick={() => {
											props.onClose();
										}}
										className={classes.ContactUs}
									>
										<CustomNavLink to="/contact-us" target="_self" className={classes.links}>
											<span>{t(keys.GLOBAL.NAVBAR.CONTACT_US)}</span>
										</CustomNavLink>
									</li>
								</ul>
							)}

							{selectedParentId !== "" && selectedChilds && (
								<ul className={`${props.onOpen ? classes[props.onOpen] : ""}`}>
									{selectedChilds.map((child) => (
										<li key={child.id} id={child.id} onClick={() => onClick(child)}>
											{child.linkTo && (
												<CustomNavLink
													to={child.linkTo}
													target={child.target}
													className={classes.links}
												>
													<span>{child.title}</span>
												</CustomNavLink>
											)}
											{!child.linkTo && (
												<div className={classes.links}>
													<span>{child.title}</span>
												</div>
											)}
										</li>
									))}
								</ul>
							)}
						</div>
					</div>
					<Icon
						className={`${classes.iconClose} ${props.onOpen ? classes[props.onOpen] : ""}`}
						onClick={props.onClose}
						icon="bi:x-lg"
					/>
					{selectedParentId !== "" && (
						<Icon
							className={`${classes.iconBack} ${props.onOpen ? classes[props.onOpen] : ""}`}
							onClick={backToParent}
							icon="bytesize:arrow-left"
						/>
					)}
				</div>,
				protalElement
			)}
		</Fragment>
	);
};

NavOverlay.defaultProps = {
	classes,
};

export default NavOverlay;
