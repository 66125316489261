import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

export const SearchSelectDropdown = React.forwardRef(
	({ label, name, required, options, optionLabel, value, onChange, InputLabelProps }, ref) => {
		const handleOnChange = (_, value) => {
			onChange(value);
		};

		return (
			<Autocomplete
				ref={ref}
				options={options}
				getOptionLabel={(option) =>  option?.[optionLabel] || ""}
				value={value}
				onChange={handleOnChange}
				renderInput={(params) => <TextField {...params} label={label} name={name} required={required} InputLabelProps={InputLabelProps} />}
			/>
		);
	}
);

SearchSelectDropdown.defaultProps = {
	label: "",
	name: "",
	required: false,
	options: [],
	optionLabel: "label",
	value: null,
	onChange: () => {},
	InputLabelProps: {}
};
