import React, { useState, useRef, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import { Icon } from "@iconify/react";

import { keys } from "../../../../locales/localeskeys";
import { constants } from "../../../../locales/constant";

import { Button } from "../../../../atoms/button/Button";
import { SearchSelectDropdown, CheckboxInput, TextInput } from "../../../../atoms/forms";

import {
	addPaymentMethod,
	paymentMethodSelector,
	resetStatus,
	updatePaymentMethod
} from "../../../../store/paymentMethod";

import { useLocalized } from "../../../../hooks";

import classes from "./PaymentMethodsForm.module.scss";

export const PaymentMethodsForm = ({ classes }) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;
	const { getLocalizedValueByKeyType } = useLocalized(i18n.language);

	const navigate = useNavigate();

	const [statusAddPaymentMethod, statusUpdatePaymentMethod] = useSelector((state) => [
		state.paymentMethods.status.addPaymentMethod,
		state.paymentMethods.status.updatePaymentMethod,
	]);
	const paymentMethod = useSelector(paymentMethodSelector);
	const error = useSelector((state) => state.paymentMethods.error)

	const [country, setCountry] = useState(paymentMethod?.country || {});
	const [state, setState] = useState(paymentMethod?.state || {});
	const [states, setStates] = useState([]);

	const zipCodeRef = useRef();

	const minMonth = useMemo(() => {
		const date = new Date();
		return `${date.getFullYear()}-${date.getMonth()}`;
	}, []);

	const expiresAt = useMemo(() => {
		if (paymentMethod.expires_at) {
			const [month, year] = paymentMethod.expires_at.split("/");
			return `${year}-${month}`;
		}
	}, [paymentMethod]);

	const handleUppercaseChange = (e) => {
		if (zipCodeRef.current) {
			zipCodeRef.current.value = e.target.value.toUpperCase();
		}
	};

	const handleCountry = (value) => {
		setCountry(value);
		setState({});
	};

	const handleOnSave = (e) => {
		e.preventDefault();

		const [year, month] = e.target.elements.expires_at.value.toString().split("-");

		if (!paymentMethod.id) {
			dispatch(
				addPaymentMethod({
					card_number: e.target.elements.card_number.value,
					cardholder: e.target.elements.cardholder.value,
					expires_at: `${month}/${year}`,
					cvv: e.target.elements.cvv.value,
					is_default: e.target.elements.is_default.checked,
					street_address: e.target.elements.street_address.value,
					zip_code: e.target.elements.zip_code.value,
					country: country.id,
					state: state.id,
					city: e.target.elements.city.value,
					unit: e.target.elements.unit.value,
					phone_number: e.target.elements.phone_number.value,
				})
			);
		} else {
			dispatch(
				updatePaymentMethod({
					id: paymentMethod.id,
					expires_at: `${month}/${year}`,
					is_default: e.target.elements.is_default.checked,
					street_address: e.target.elements.street_address.value,
					zip_code: e.target.elements.zip_code.value,
					country: country.id,
					state: state.id,
					city: e.target.elements.city.value,
					unit: e.target.elements.unit.value,
					phone_number: e.target.elements.phone_number.value,
				})
			);
		}
	};

	const handleOnCancel = () => {
		dispatch(resetStatus());
		navigate("/account/payment-methods");
	};

	useEffect(() => {
		if (country?.states) {
			setStates(country.states);
		}
	}, [country]);

	useEffect(() => {
		if (statusAddPaymentMethod === "succeeded" || statusUpdatePaymentMethod === "succeeded") {
			dispatch(resetStatus());
			navigate("/account/payment-methods");
		}
	}, [statusAddPaymentMethod, statusUpdatePaymentMethod]);

	return (
		<form onSubmit={handleOnSave}>
			<div className={classes.frameset}>
				<Alert severity="info">{t(keys.ACCOUNT.PAYMENT_METHODS.FORM.ALERT)}</Alert>
				{error?.error_code && <Alert severity="error">{ getLocalizedValueByKeyType(error, "description") }</Alert>}
				<div className={classes.section}>
					<div className={clsx(classes.bold, classes.article)}>
						{t(keys.ACCOUNT.PAYMENT_METHODS.FORM.CARD_INFORMATION)}
					</div>
					<div className={classes.article}>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.CARD_NUMBER)}
								name="card_number"
								value={paymentMethod.card_number || ""}
								required={true}
								disabled={paymentMethod.id}
								pattern="[0-9]{13,18}"
							/>
						</div>
					</div>
					<div className={classes.article}>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.CARDHOLDER)}
								name="cardholder"
								value={paymentMethod.cardholder || ""}
								required={true}
								disabled={paymentMethod.id}
							/>
						</div>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.EXPIRATION_DATE)}
								name="expires_at"
								required={true}
								type="month"
								inputProps={{ min: minMonth }}
								inputLabelProps={{ shrink: true }}
								value={expiresAt || ""}
							/>
						</div>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.CVV)}
								name="cvv"
								required={true}
								disabled={paymentMethod.id}
								pattern="[0-9]{3,4}"
							/>
						</div>
					</div>
					<div className={classes.article}>
						<CheckboxInput 
							label={t(keys.ACCOUNT.CARD.SET_BY_DEFAULT)} 
							name="is_default" 
							value={true}
							checked={paymentMethod.is_default} />
					</div>
				</div>
				<div className={classes.section}>
					<div className={clsx(classes.bold, classes.article)}>
						{t(keys.ACCOUNT.PAYMENT_METHODS.FORM.BILLING_INFORMATION)}
					</div>
					<div className={classes.article}>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.STREET_ADDRESS)}
								name="street_address"
								required={true}
								value={paymentMethod.street_address || ""}
							/>
						</div>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.ZIP_CODE)}
								name="zip_code"
								required={true}
								pattern="[A-Za-z0-9]{4,10}"
								onChange={handleUppercaseChange}
								ref={zipCodeRef}
								value={paymentMethod.zip_code || ""}
							/>
						</div>
					</div>
					<div className={classes.article}>
						<div className={classes.item}>
							<SearchSelectDropdown
								options={constants.COUNTRIES}
								optionLabel="name"
								required={true}
								label={t(keys.ACCOUNT.ADDRESS.FORM.COUNTRY)}
								name="country"
								value={country}
								onChange={handleCountry}
							/>
						</div>
						<div className={classes.item}>
							<SearchSelectDropdown
								options={states}
								optionLabel="name"
								required={true}
								label={t(keys.ACCOUNT.ADDRESS.FORM.STATE)}
								name="state"
								value={state}
								onChange={(value) => setState(value)}
							/>
						</div>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.ADDRESS.FORM.CITY)}
								name="city"
								required={true}
								value={paymentMethod.city || ""}
							/>
						</div>
					</div>
					<div className={classes.article}>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.UNIT)}
								name="unit"
								value={paymentMethod.unit || ""}
							/>
						</div>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.PHONE_NUMBER)}
								name="phone_number"
								required={true}
								pattern="[0-9+ ]{9,40}"
								value={paymentMethod.phone_number || ""}
							/>
						</div>
					</div>
				</div>
				<div className={classes.footer}>
					<Button type="submit">
						{statusAddPaymentMethod === "loading" || statusUpdatePaymentMethod === "loading" ? (
							<Icon icon="ei:spinner-3" width="24" height="24" className={classes.spinner} />
						) : (
							t(keys.ACCOUNT.PAYMENT_METHODS.FORM.SAVE)
						)}
					</Button>
					<Button color="outlined" onClick={handleOnCancel}>
						{t(keys.ACCOUNT.PAYMENT_METHODS.FORM.CANCEL)}
					</Button>
				</div>
			</div>
		</form>
	);
};

PaymentMethodsForm.defaultProps = {
	classes,
};
