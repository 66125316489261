import { Helmet } from "react-helmet";
import Nichiha from "../components/partner/Nichiha/Nichiha";

const NichihaPage = () => {
	return (
		<>
			<Helmet>
				<title>{"Nichiha | Adfast"}</title>
				<meta name="description" content={"Nichiha | Adfast"} />
				<meta property="og:title" content={"Nichiha | Adfast"} />
				<meta property="og:description" content={"Nichiha | Adfast"} />
				<meta name="robots" content="index, follow" />
				<meta property="og:type" content="website" />
				<link rel="apple-touch-icon" sizes="180x180" href="/static/favicon/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/static/favicon/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/static/favicon/favicon-16x16.png" />
				<link rel="manifest" href="/static/favicon/site.webmanifest" />
			</Helmet>
			<Nichiha />
		</>
	);
};

export default NichihaPage;
