import cookie from "js-cookie";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { constants } from "../locales/constant";

const initialState = {
	isLoggedOut: false,
	isLoggedIn: false,
	is_subscribed_newsletter: false,
	currentRequestId: undefined,
	error: undefined,
	loading: "done",
	editingAddress: false,
	precedentUrl: undefined,
	resetSent: false,
	resetFailed: false,
	advertising: true,
	functional: true,
	analytical: true,
	savedCookie: false,
	shippingAddress: {},
	isCreditCardEnabled: undefined,
	paymentMethods: []
};

export const saveCookies = createAsyncThunk(
	"auth/saveCookies",

	async ({ Advertising, Functional, Analytical, savedCookie }, { rejectWithValue, getState }) => {
		try {
			const {
				auth: { access_token },
			} = getState();
			const customerManagerId = cookie.get("cusmanagerid");
			if (!customerManagerId) {
				throw new Error("Customer manager load failed due to cookies not found");
			}
			const header = new Headers();
			if (access_token) {
				header.append("Authorization", "Bearer " + access_token);
			}
			header.append("Content-Type", "application/json");
			const response = await fetch(`${constants.API_SERVER}/customerManager/${customerManagerId}/`, {
				method: "PUT",
				credentials: "include",
				headers: header,
				body: JSON.stringify({
					advertising: Advertising,
					functional: Functional,
					analytical: Analytical,
					saved_cookie_flag: savedCookie,
				}),
			});
			if (!response.ok) {
				throw new Error("Save customer Cookies policy failed");
			}
			return { Advertising, Functional, Analytical, savedCookie };
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * Utiliser l'email et le mot de passe entree par l'utilisateur pour se connecter
 */
export const login = createAsyncThunk(
	"auth/login",
	/**
	 * Utiliser l'email et le mot de passe entree par l'utilisateur pour se connecter
	 * @param email
	 * @param password
	 * @param stayConnected
	 * @param rejectWithValue
	 */
	async ({ email, password, stayConnected }, { rejectWithValue }) => {
		try {
			const response = await fetch(`${constants.API_SERVER}/login/`, {
				method: "POST",
				credentials: stayConnected ? "include" : "omit",
				redirect: "follow",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					email: email,
					password: password,
				}),
			});
			if (!response.ok) {
				const errors = await response.json();
				throw new Error(errors.non_field_errors[0]);
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * Enregistrer un utilisateur et envoyer un email de confirmation
 */
export const signup = createAsyncThunk(
	"auth/signup",
	/**
	 * Enregistrer un utilisateur et envoyer un email de confirmation
	 * @param email
	 * @param user
	 * @param rejectWithValue
	 */
	async (user, { rejectWithValue }) => {
		try {
			const response = await fetch(`${constants.API_SERVER}/register/`, {
				method: "POST",
				credentials: "omit",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(user),
			});
			if (!response.ok) {
				const errors = await response.json();
				throw new Error(errors.non_field_errors[0]);
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * Utiliser les cookies httponly pour rafraichir la connexion
 * cette methode est appele en boucle par app.js
 */
export const refresh = createAsyncThunk(
	"auth/refresh",
	/**
	 * Utiliser les cookies httponly pour rafraichir la connexion
	 * @param _
	 * @param rejectWithValue
	 * @param getState
	 * @param dispatch
	 * @returns {Promise<{customerData, refreshData: any}>}
	 */
	async (_, { rejectWithValue, getState, dispatch }) => {
		try {
			/**
			 * rafraichir le token d'access
			 */
			let {
				auth: { refresh_token },
			} = getState();
			const header = new Headers();
			let response;
			if (refresh_token) {
				header.append("Content-Type", "application/json");
				response = await fetch(`${constants.API_SERVER}/refresh/`, {
					method: "POST",
					credentials: "include",
					headers: header,
					body: JSON.stringify({ refresh: refresh_token }),
				});
			} else {
				response = await fetch(`${constants.API_SERVER}/refresh/`, {
					method: "POST",
					credentials: "include",
					headers: header,
				});
			}
			if (!response.ok) {
				const errors = await response.json();
				throw new Error(errors.detail);
			}
			const refreshData = await response.json();
			/**
			 * rafraichir le compte client, logoff si sa echoue
			 */
			let customerData;
			const {
				auth: { customer },
			} = getState();
			if (!customer) {
				const customerId = cookie.get("custid");
				if (!customerId) {
					throw new Error("Customer load failed due to cookies not found");
				}
				const header = new Headers();
				header.append("Authorization", "Bearer " + refreshData.access);
				const response = await fetch(`${constants.API_SERVER}/customer/${customerId}/`, {
					credentials: "include",
					headers: header,
				});
				if (!response.ok) {
					throw new Error("Customer load failed");
				}
				customerData = await response.json();
			}
			return {
				refreshData,
				customerData,
			};
		} catch (err) {
			await dispatch(logout());
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * supprimer les cookies et reintialiser le reducer d'authentification
 * cette methode est appele par l'utilisateur ou quand le rafraichissement echoue
 * si le fetch renvoie une erreur,
 */
export const logout = createAsyncThunk(
	"auth/logout",
	/**
	 * supprimer les cookies et reintialiser le reducer d'authentification
	 * @param _
	 * @param rejectWithValue
	 * @param getState
	 * @param dispatch
	 */
	async (_, { rejectWithValue, getState, dispatch }) => {
		try {
			const {
				auth: { access_token },
			} = getState();
			const header = new Headers();
			if (access_token) {
				header.append("Authorization", "Bearer " + access_token);
			}
			const response = await fetch(`${constants.API_SERVER}/logout/`, {
				method: "POST",
				credentials: "include",
				headers: header,
			});
			if (!response.ok) {
				throw new Error("Logout failed");
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * s'inscrire a la liste de newsletters de pardot
 * rajouter un cookie pour indiquer que le client est inscrie au newsletter
 */
export const subscribeNewsLetter = createAsyncThunk(
	"auth/subscribeNewsLetter",
	async (subNewsLetter, { rejectWithValue, getState }) => {
		try {
			const {
				auth: { id },
			} = getState();
			if (id) {
				subNewsLetter.customer = id;
			}
			const header = new Headers();
			header.append("Content-Type", "application/json");
			const response = await fetch(`${constants.API_SERVER}/newsletterSubscribe/`, {
				method: "POST",
				credentials: "include",
				headers: header,
				body: JSON.stringify(subNewsLetter),
			});
			if (!response.ok) {
				const error = await response.json();
				if (
					error["non_field_errors"] &&
					error.non_field_errors[0] === "The fields email must make a unique set."
				) {
					return subNewsLetter;
				}
				throw new Error("Subscribtion to news letter failed");
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * rajouter un produit dans les favorits du client
 */
export const addItemFavorite = createAsyncThunk(
	"auth/addItemFavorite",
	/**
	 * rajouter un produit dans les favorits du client
	 * @param itemId
	 * @param brandId
	 * @param rejectWithValue
	 * @param getState
	 */
	async ({ itemId, brandId }, { rejectWithValue, getState }) => {
		try {
			const {
				auth: { access_token },
			} = getState();
			const {
				auth: { id },
			} = getState();
			const header = new Headers();
			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json");
			const response = await fetch(`${constants.API_SERVER}/CustomerItem/`, {
				method: "POST",
				headers: header,
				body: JSON.stringify({
					item: itemId,
					brand: brandId,
					customer: id,
				}),
			});
			if (!response.ok) {
				const errors = await response.json();
				throw new Error(errors.non_field_errors[0]);
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * rajouter un produit dans les favorits du client
 */
export const deleteItemFavorite = createAsyncThunk(
	"auth/deleteItemFavorite",
	/**
	 * rajouter un produit dans les favorits du client
	 * @param id
	 * @param rejectWithValue
	 * @param getState
	 */
	async (id, { rejectWithValue, getState }) => {
		try {
			const {
				auth: { access_token },
			} = getState();
			const header = new Headers();
			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json");
			const response = await fetch(`${constants.API_SERVER}/CustomerItem/${id}/`, {
				method: "DELETE",
				headers: header,
			});
			if (!response.ok) {
				const errors = await response.json();
				throw new Error(errors.non_field_errors[0]);
			}
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * rajouter une specification dans les favorits du client
 */
export const addSpecificationFavorite = createAsyncThunk(
	"auth/addSpecificationFavorite",
	/**
	 * rajouter une specification dans les favorits du client
	 * @param specificationId
	 * @param rejectWithValue
	 * @param getState
	 */
	async (specificationId, { rejectWithValue, getState }) => {
		try {
			const {
				auth: { access_token },
			} = getState();
			const {
				auth: { id },
			} = getState();
			const header = new Headers();
			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json");
			const response = await fetch(`${constants.API_SERVER}/CustomerSpecification/`, {
				method: "POST",
				headers: header,
				body: JSON.stringify({
					specification: specificationId,
					customer: id,
				}),
			});
			if (!response.ok) {
				const errors = await response.json();
				throw new Error(errors.non_field_errors[0]);
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * rajouter un produit dans les favorits du client
 */
export const deleteSpecificationFavorite = createAsyncThunk(
	"auth/deleteSpecificationFavorite",
	/**
	 * rajouter une specification dans les favorits du client
	 * @param specificationId
	 * @param rejectWithValue
	 * @param getState
	 */
	async (specificationId, { rejectWithValue, getState }) => {
		try {
			const {
				auth: { access_token },
			} = getState();
			const {
				auth: { favorite_specification },
			} = getState();
			const fav = favorite_specification.find((f) => f.specification === specificationId);
			const header = new Headers();
			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json");
			const response = await fetch(`${constants.API_SERVER}/CustomerSpecification/${fav.id}/`, {
				method: "DELETE",
				headers: header,
			});
			if (!response.ok) {
				const errors = await response.json();
				throw new Error(errors.non_field_errors[0]);
			}
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * envoyer une demande de quote a un rep
 */
export const quoteRequest = createAsyncThunk(
	"auth/quoteRequest",
	/**
	 * envoyer une demande de quote a un rep
	 * @param specificationId
	 * @param brandId
	 * @param formatId
	 * @param rejectWithValue
	 * @param getState
	 */
	async ({ specificationId, brandId, formatId }, { rejectWithValue, getState }) => {
		try {
			const {
				auth: { access_token },
			} = getState();
			const header = new Headers();
			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json");
			const response = await fetch(`${constants.API_SERVER}/requestQuote/`, {
				method: "POST",
				headers: header,
				body: JSON.stringify({
					specification: specificationId,
					brand: brandId,
					format: formatId,
				}),
			});
			if (!response.ok) {
				const errors = await response.json();
				throw new Error(errors.non_field_errors[0]);
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * envoyer un lien a l'email de l'utilisateur pour reintialiser son mot de passe
 */
export const forgotPassword = createAsyncThunk(
	"auth/forgotPassword",
	/**
	 * envoyer un lien a l'email de l'utilisateur pour reintialiser son mot de passe
	 * @param email
	 * @param lngId
	 * @param rejectWithValue
	 * @param getState
	 */
	async ({ email, lngId }, { rejectWithValue, getState }) => {
		try {
			const header = new Headers();
			header.append("Content-Type", "application/json");
			const response = await fetch(`${constants.API_SERVER}/password-reset/`, {
				method: "POST",
				headers: header,
				body: JSON.stringify({
					email: email,
					lng_id: lngId,
				}),
			});
			if (!response.ok) {
				const errors = await response.json();
				throw new Error(errors.non_field_errors[0]);
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * reintialiser le mot de passe de l'utilisateur
 */
export const resetPassword = createAsyncThunk(
	"auth/resetPassword",
	/**
	 * reintialiser le mot de passe de l'utilisateur
	 * @param new_password1
	 * @param new_password2
	 * @param uid
	 * @param token
	 * @param rejectWithValue
	 * @param getState
	 */
	async ({ new_password1, new_password2, uid, token }, { rejectWithValue, getState }) => {
		try {
			const header = new Headers();
			header.append("Content-Type", "application/json");
			const response = await fetch(`${constants.API_SERVER}/password-reset-confirm/${uid}/${token}/`, {
				method: "POST",
				headers: header,
				body: JSON.stringify({
					new_password1: new_password1,
					new_password2: new_password2,
					uid: uid,
					token: token,
				}),
			});
			if (!response.ok) {
				const errors = await response.json();
				throw new Error(errors.non_field_errors[0]);
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * confirmer l'email de l'utilisateur
 */
export const emailConfirmation = createAsyncThunk(
	"auth/emailConfirmation",
	/**
	 * confirmer l'email de l'utilisateur
	 * @param token
	 * @param rejectWithValue
	 * @param getState
	 */
	async (token, { rejectWithValue, getState }) => {
		try {
			const header = new Headers();
			header.append("Content-Type", "application/json");
			const response = await fetch(`${constants.API_SERVER}/account-confirm-email/${token}/`, {
				method: "POST",
				headers: header,
				body: JSON.stringify({
					key: token,
				}),
			});
			if (!response.ok) {
				const errors = await response.json();
				throw new Error(errors.non_field_errors[0]);
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * Rajouter une addresse client
 */
export const addNewAddress = createAsyncThunk(
	"auth/addNewAddress",
	/**
	 * Rajouter une addresse client
	 * @param address
	 * @param rejectWithValue
	 * @param getState
	 */
	async (address, { rejectWithValue, getState }) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const header = new Headers();
			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json");
			const response = await fetch(`${constants.API_SERVER}/address/`, {
				method: "POST",
				headers: header,
				body: JSON.stringify(address),
			});
			if (!response.ok) {
				const errors = await response.json();
				throw new Error(errors.non_field_errors[0]);
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * Modifier une addresse client
 */
export const updateNewAddress = createAsyncThunk(
	"auth/updateNewAddress",
	/**
	 * Modifier une addresse client
	 * @param address
	 * @param rejectWithValue
	 * @param getState
	 */
	async (address, { rejectWithValue, getState }) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const header = new Headers();
			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json");
			const response = await fetch(`${constants.API_SERVER}/address/${address.id}/`, {
				method: "PUT",
				headers: header,
				body: JSON.stringify(address),
			});
			if (!response.ok) {
				const errors = await response.json();
				throw new Error(errors.non_field_errors[0]);
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * Modifier une addresse client
 */
export const setDefaultAddress = createAsyncThunk(
	"auth/setDefaultAddress",
	/**
	 * Modifier une addresse client
	 * @param address
	 * @param rejectWithValue
	 * @param getState
	 */
	async ({ oldAddress, newAddress }, { rejectWithValue, getState }) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const addresses = [];

			const header = new Headers();
			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json");
			if (oldAddress) {
				const responseOld = await fetch(`${constants.API_SERVER}/address/${oldAddress.id}/`, {
					method: "PUT",
					headers: header,
					body: JSON.stringify(oldAddress),
				});
				if (!responseOld.ok) {
					const errors = await responseOld.json();
					throw new Error(errors.non_field_errors[0]);
				}
				addresses.push(await responseOld.json());
			}
			const responseNew = await fetch(`${constants.API_SERVER}/address/${newAddress.id}/`, {
				method: "PUT",
				headers: header,
				body: JSON.stringify(newAddress),
			});
			if (!responseNew.ok) {
				const errors = await responseNew.json();
				throw new Error(errors.non_field_errors[0]);
			}
			addresses.push(await responseNew.json());
			return addresses;
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * Rajouter une carte de credit
 */
export const addNewWallet = createAsyncThunk(
	"auth/addNewWallet",
	/**
	 * Rajouter une addresse client
	 * @param wallet
	 * @param rejectWithValue
	 * @param getState
	 */
	async (wallet, { rejectWithValue, getState }) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const {
				auth: { id: customer },
			} = getState();
			wallet.customer = customer;
			wallet.card_number = wallet.card_number.replace(/\s/g, "");
			wallet.expiry_date = wallet.expiry_date.replace(/\D/g, "");

			const header = new Headers();
			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json");
			const response = await fetch(`${constants.API_SERVER}/wallet/`, {
				method: "POST",
				headers: header,
				body: JSON.stringify(wallet),
			});
			if (!response.ok) {
				const errors = await response.json();
				throw new Error(errors.non_field_errors[0]);
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * Supprimer l'addresse d'un client
 */
export const deleteAddress = createAsyncThunk(
	"auth/deleteAddress",
	/**
	 * Supprimer l'addresse d'un client
	 * @param id
	 * @param rejectWithValue
	 * @param getState
	 */
	async (id, { rejectWithValue, getState }) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const header = new Headers();
			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json");
			const response = await fetch(constants.API_SERVER + `/address/${id}/`, {
				method: "DELETE",
				credentials: "include",
				headers: header,
			});
			if (!response.ok) {
				throw new Error("deleting address failed");
			}
			return id;
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * Supprimer la carte de credit d'un client
 */
export const deleteWallet = createAsyncThunk(
	"auth/deleteWallet",
	/**
	 * Supprimer la carte de credit d'un client
	 * @param id
	 * @param rejectWithValue
	 * @param getState
	 */
	async (id, { rejectWithValue, getState }) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const header = new Headers();
			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json");
			const response = await fetch(constants.API_SERVER + `/wallet/${id}/`, {
				method: "DELETE",
				credentials: "include",
				headers: header,
			});
			if (!response.ok) {
				throw new Error("deleting wallet failed");
			}
			return id;
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

const auth = createSlice({
	name: "auth",
	initialState: initialState,
	reducers: {
		setCurrency(state, action) {
			state.currencyCode = action.payload;
		},
		setPrecedentUrl(state, action) {
			state.loading = "pending";
			state.precedentUrl = action.payload;
			state.loading = "done";
		},
		setCookie(state, action) {
			state.advertising = action.payload.advertising;
			state.functional = action.payload.functional;
			state.analytical = action.payload.analytical;
			state.savedCookie = action.payload.savedCookie;
			cookie.set("cookiesPolicy", action.payload, { sameSite: "lax", expires: 9999 });
		},
		setShippingAddress(state, action) {
			state.shippingAddress = action.payload
		}
	},
	extraReducers: {
		[saveCookies.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;
			}
			return state;
		},
		[saveCookies.pending]: (state, { meta }) => {
			if (state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[saveCookies.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
		[login.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.error = undefined;
				state.loading = "done";
				state.isLoggedIn = true;
				state.isLoggedOut = false;
				state.access_token_expiration = payload.access_expiration;
				state.access_token = payload.access;
				state.refresh_token = payload.refresh;
				state.first_name = payload.user?.first_name;
				state.last_name = payload.user?.last_name;
				state.email = payload.user?.email;
				state.username = payload.user?.username;
				state.userid = payload.user?.userid;
				state.currencyCode = payload.user?.customer?.currency?.code;
				state.lngId = payload.user?.customer?.language?.code;
				state.isCreditCardEnabled = payload.user?.customer?.is_credit_card_enabled;
				state.paymentMethods = payload.user?.customer?.payment_methods;

				Object.assign(state, payload.user?.customer);

				if (state.id) {
					cookie.set("custid", state.id, { sameSite: "lax", expires: 9999 });
				}
				if (payload.user.cookies.customer_manager_id) {
					cookie.set("cusmanagerid", payload.user.cookies.customer_manager_id, {
						sameSite: "lax",
						expires: 9999,
					});
				}

				state.advertising = payload.user?.cookies?.advertising;
				state.functional = payload.user?.cookies?.functional;
				state.analytical = payload.user?.cookies?.analytical;
				state.savedCookie = payload.user?.cookies?.saved_cookie_flag;
				const cookies = cookie.get("cookiesPolicy");
				if (cookies === undefined) {
					cookie.set(
						"cookiesPolicy",
						{
							advertising: payload.user?.cookies?.advertising,
							functional: payload.user?.cookies?.functional,
							analytical: payload.user?.cookies?.analytical,
							savedCookie: payload.user?.cookies?.saved_cookie_flag,
						},
						{
							sameSite: "lax",
							expires: 9999,
						}
					);
				}
			}
			return state;
		},
		[login.pending]: (state, { meta }) => {
			if (state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[login.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
				const cookies = cookie.get("cookiesPolicy");
				if (cookies !== undefined) {
					let cookiesJson = JSON.parse(cookies);
					state.advertising = cookiesJson.Advertising;
					state.functional = cookiesJson.Functional;
					state.analytical = cookiesJson.Analytical;
					state.savedCookie = cookiesJson.savedCookie;
				}
			}
			return state;
		},
		[signup.fulfilled]: (state, { meta }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.error = undefined;
				state.loading = "done";
				state.isSignUp = true;
			}
			return state;
		},
		[signup.pending]: (state, { meta }) => {
			if (state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[signup.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
		[refresh.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;
				state.isLoggedIn = true;
				state.access_token_expiration = payload?.refreshData?.access_expiration;
				state.access_token = payload?.refreshData?.access;
				state.refresh_token = payload?.refreshData?.refresh;
				state.currencyCode = payload.customerData.currency.code;
				state.first_name = payload.customerData?.first_name;
				state.last_name = payload.customerData?.last_name;
				state.email = payload.customerData?.email;
				state.lngId = payload.customerData.language.code;
				state.isCreditCardEnabled = payload.customerData.is_credit_card_enabled;
				state.paymentMethods = payload.customerData.payment_methods;

				let { saved_cookie_flag, ...customerDataWithoutSavedCookies } = payload.customerData;
				Object.assign(state, customerDataWithoutSavedCookies);
				state.savedCookie = saved_cookie_flag;

				if (state && state.user && state.user.customer && state.user.customer.group) {
					state.user.customer.group = payload.user?.customer?.group.prices;
				}
			}
			return state;
		},
		[refresh.pending]: (state, { meta }) => {
			if (state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[logout.fulfilled]: (state, { meta }) => {
			if (meta.requestId === state.currentRequestId) {
				for (const key in state) {
					if (
						key === "currencyCode" ||
						key === "lngId" ||
						key === "savedCookie" ||
						key === "advertising" ||
						key === "functional" ||
						key === "analytical"
					)
						continue;
					state[key] = undefined;
				}
				state.isLoggedIn = false;
				state.isLoggedOut = true;
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;
				state.isCreditCardEnabled = false;
				state.paymentMethods = [];

				cookie.remove("custid");
				state.is_subscribed_newsletter = !!cookie.get("subToNewsletter");
			}

			const cookies = cookie.get("cookiesPolicy");
			if (cookies !== undefined) {
				let cookiesJson = JSON.parse(cookies);
				state.advertising = cookiesJson.Advertising;
				state.functional = cookiesJson.Functional;
				state.analytical = cookiesJson.Analytical;
				state.savedCookie = cookiesJson.savedCookie;
			}
			return state;
		},
		[logout.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.loading = "pending";
			state.error = undefined;
			return state;
		},
		[logout.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				for (const key in state) {
					if (
						key === "currencyCode" ||
						key === "lngId" ||
						key === "savedCookie" ||
						key === "advertising" ||
						key === "functional" ||
						key === "analytical"
					)
						continue;
					state[key] = undefined;
				}
				state.isLoggedIn = false;
				state.isLoggedOut = true;
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;

				cookie.remove("custid");

				state.is_subscribed_newsletter = !!cookie.get("subToNewsletter");

				const cookies = cookie.get("cookiesPolicy");
				if (cookies !== undefined) {
					let cookiesJson = JSON.parse(cookies);
					state.advertising = cookiesJson.Advertising;
					state.functional = cookiesJson.Functional;
					state.analytical = cookiesJson.Analytical;
					state.savedCookie = cookiesJson.savedCookie;
				}
			}
			return state;
		},
		[subscribeNewsLetter.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.is_subscribed_newsletter = true;
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				if (payload.id) {
					cookie.set("subToNewsletter", payload.id, { sameSite: "lax", expires: 9999 });
				}
			}
			return state;
		},
		[subscribeNewsLetter.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.loading = "pending";
			state.error = undefined;
			return state;
		},
		[subscribeNewsLetter.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.is_subscribed_newsletter = false;
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
		[addItemFavorite.fulfilled]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";

				state.favorite_item.push(payload);
				return state;
			}
		},
		[addItemFavorite.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.loading = "pending";
			state.error = undefined;
			return state;
		},
		[addItemFavorite.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
		[deleteItemFavorite.fulfilled]: (state, { meta }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";

				state.favorite_item = state.favorite_item.filter((fav) => fav.id !== meta.arg);
				return state;
			}
		},
		[deleteItemFavorite.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.loading = "pending";
			state.error = undefined;
			return state;
		},
		[deleteItemFavorite.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
		[addSpecificationFavorite.fulfilled]: (state, { payload }) => {
			state.currentRequestId = undefined;
			state.loading = "done";

			state.favorite_specification.push(payload);
			return state;
		},
		[addSpecificationFavorite.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.loading = "pending";
			state.error = undefined;
			return state;
		},
		[addSpecificationFavorite.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
		[deleteSpecificationFavorite.fulfilled]: (state, { meta }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";

				state.favorite_specification = state.favorite_specification.filter(
					(fav) => fav.specification !== meta.arg
				);
				return state;
			}
		},
		[deleteSpecificationFavorite.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.loading = "pending";
			state.error = undefined;
			return state;
		},
		[deleteSpecificationFavorite.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
		[addNewAddress.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				state.customerAddresses.push(payload);
			}
		},
		[addNewAddress.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[addNewAddress.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
		[addNewWallet.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				state.wallet.push(payload);
			}
		},
		[addNewWallet.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[addNewWallet.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
		[updateNewAddress.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				const addresses = state.customerAddresses;
				const index = addresses.findIndex((add) => add.id === payload.id);
				addresses[index] = payload;
				state.customerAddresses = addresses;
			}
		},
		[updateNewAddress.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[updateNewAddress.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
		[setDefaultAddress.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				payload.forEach((address) => {
					const addresses = state.customerAddresses;
					const index = addresses.findIndex((add) => add.id === address.id);
					addresses[index] = address;
					state.customerAddresses = addresses;
				});
			}
		},
		[setDefaultAddress.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[setDefaultAddress.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
		[deleteAddress.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				state.customerAddresses = state.customerAddresses.filter((l) => l.id !== payload);
			}
		},
		[deleteAddress.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[deleteAddress.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
		[deleteWallet.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				state.wallet = state.wallet.filter((l) => l.id !== payload);
			}
		},
		[deleteWallet.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[deleteWallet.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
		[resetPassword.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;
				state.resetSent = true;
				state.resetFailed = false;
			}
		},
		[resetPassword.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
				state.resetSent = false;
				state.resetFailed = false;
			}
			return state;
		},
		[resetPassword.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
				state.resetSent = true;
				state.resetFailed = true;
			}
			return state;
		},
	},
});

export const authActions = auth.actions;
export default auth;

export const { setCurrency, setPrecedentUrl, setCookie, setShippingAddress } = auth.actions;
export const isLoadingSelector = (state) => state?.auth?.loading === "pending";
export const isLoggedInSelector = (state) => state?.auth?.isLoggedIn;
export const precedentUrlSelector = (state) => state?.auth?.precedentUrl;
export const isLoggedOutSelector = (state) => state?.auth?.isLoggedOut;
export const isSignUpSelector = (state) => state?.auth?.isSignUp;
export const isSubNewsLetterSelector = (state) => state?.auth?.is_subscribed_newsletter;
export const authHasErrorSelector = (state) => !!state?.auth?.error && state.auth.error !== "";
export const accessTokenSelector = (state) => state?.auth?.access_token;
export const accessExpSelector = (state) => state?.auth?.access_token_expiration;
export const emailSelector = (state) => state?.auth?.email;
export const firstNameSelector = (state) => state?.auth?.first_name;
export const lastNameSelector = (state) => state?.auth?.last_name;
export const usernameSelector = (state) => state?.auth?.username;
export const customerIdSelector = (state) => state?.auth?.id;
export const customerFavoriteItemSelector = (state) =>
	state?.auth?.favorite_item?.map((fav) => {
		return { id: fav.id, item: fav?.item, brand: fav?.brand };
	});
export const customerFavoriteBrandSelector = (state) =>
	state?.auth?.favorite_item?.map((fav) => fav?.brand).filter((value, index, self) => self.indexOf(value) === index);
export const customerFavoriteSpecificationSelector = (state) =>
	state?.auth?.favorite_specification?.map((fav) => fav?.specification);

export const addressSelector = (state) => state?.auth?.customerAddresses;

export const shippingAddressSelector = (state) => {
	const country = constants.COUNTRIES.find(c => c.id === state?.auth?.shippingAddress?.country)
	const province = country?.states.find(p => p.id === state?.auth?.shippingAddress?.state)

	return {
		...state?.auth?.shippingAddress,
		country, 
		state: province
	}
};

export const defaultAddressSelector = (state) => 
	state?.auth?.customerAddresses
		?.filter((address) => address.is_default === true)
		.map((address) => ({
			id: address.id,
			name: address.name,
			city: address.city,
			postalCode: address.postal_code,
			state: address.state,
			country: address.country,
			line1: address.line1,
			line2: address.line2,
			customer: address.customer,
			address: `${address.line1 ? address.line1 : address.line1}  ${address.line2 ? address.line2 : ""} ${
				address.city ? address.city : ""
			}, ${address.state ? address.state : ""}, ${address.postal_code ? address.postal_code : ""} ${
				address.country ? address.country : ""
			}`,
		}));
export const otherAddressesSelector = (state) =>
	state?.auth?.customerAddresses
		?.filter((address) => address.is_default === false)
		.map((address) => ({
			id: address.id,
			name: address.name,
			city: address.city,
			postalCode: address.postal_code,
			state: address.state,
			country: address.country,
			line1: address.line1,
			line2: address.line2,
			address: `${address.line1 ? address.line1 : address.line1}  ${address.line2 ? address.line2 : ""} ${
				address.city ? address.city : ""
			}, ${address.state ? address.state : ""}, ${address.postal_code ? address.postal_code : ""} ${
				address.country ? address.country : ""
			}`,
		}));
export const selectedAddressSelector = (state, id) => {
	return id ? state?.auth?.customerAddresses?.find((address) => address.id === id) : undefined;
};
export const billingAddressSelector = (state) =>
	state?.auth?.customerAddresses
		?.filter((address) => address.type === 1)
		.map((address) => ({
			id: address.id,
			name: address.name,
			city: address.city,
			postalCode: address.postalCode,
			state: address.state,
			country: address.country,
			line1: address.line1,
			line2: address.line2,
			address: `${address.line1 ? address.line1 : address.line1}  ${address.line2 ? address.line2 : ""} ${
				address.city ? address.city : ""
			}, ${address.state ? address.state : ""}, ${address.postal_code ? address.postal_code : ""} ${
				address.country ? address.country : ""
			}`,
		}));
export const taxPercentSelector = (state) => state?.auth?.tax_percent;
export const walletSelector = (state) => state?.auth?.wallet;
export const hasTermPaymSelector = (state) => state?.auth?.payment_term;
export const currencySelector = (state) => state?.auth?.currencyCode;
export const customerCodeSelector = (state) => state?.auth?.erp_code;
export const customerNameSelector = (state) => state?.auth?.name;
export const languageSelector = (state) => state?.auth?.lngId;
export const isPasswordReseted = (state) => state?.auth?.resetSent === true && state?.auth?.resetFailed === false;
export const isPasswordFailed = (state) => state?.auth?.resetFailed === true;
export const isSavedCookie = (state) => state?.auth?.savedCookie;
export const isAdvertisingEnabled = (state) => state?.auth?.savedCookie === true && state?.auth?.advertising === true;
export const isFunctionalEnabled = (state) => state?.auth?.savedCookie === true && state?.auth?.functional === true;
export const isAnalyticalEnabled = (state) => state?.auth?.savedCookie === true && state?.auth?.analytical === true;
export const isCreditCardEnabled = (state) => state?.auth?.isCreditCardEnabled;
