import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import clsx from "clsx";

import { setQuotedOnly } from "../../../store/productQuery";
import { logout, isCreditCardEnabled } from "../../../store/auth";

import { keys } from "../../../locales/localeskeys";

import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

import classes from "./Sidebar.module.scss";

const Sidebar = ({ classes }) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;
	const isAvailablePaymentMethods = useSelector(isCreditCardEnabled);

	const menuOptions = [
		{ path: "", label: t(keys.ACCOUNT.ACCOUNT.PROFILE), hidden: false },
		{ path: "accounts", label: t(keys.ACCOUNT.ACCOUNT.ACCOUNTS), hidden: true },
		{ path: "payment-methods", label: t(keys.ACCOUNT.ACCOUNT.PAYMENT_METHODS), hidden: !isAvailablePaymentMethods },
		{ path: "shipping-addresses", label: t(keys.ACCOUNT.ACCOUNT.SHIPPING_ADDRESS), hidden: false },
	];

	const [activeMenu, setActiveMenu] = useState("");
	const navigate = useNavigate();

	const handleMenuClick = (path) => {
		setActiveMenu(path);
		navigate(path);
	};

	return (
		<article className={classes.sidebar}>
			<div>
				<ul>
					{menuOptions.map(
						(menu, key) =>
							!menu["hidden"] && (
								<li
									key={key}
									className={clsx(activeMenu === menu["path"] ? classes.active : "")}
									onClick={() => handleMenuClick(menu["path"])}
								>
									{menu["label"]}
								</li>
							)
					)}
				</ul>
			</div>
			<div>
				<ul>
					<li>
						<CustomNavLink to={`/orders/?lng=${lngId}`} className={classes.textlink}>
							{t(keys.ACCOUNT.ACCOUNT.ORDERS)}
						</CustomNavLink>
					</li>
					<li>
						<CustomNavLink
							to={`/adstore/?lng=${lngId}`}
							className={classes.textlink}
							onClick={() => dispatch(setQuotedOnly(true))}
						>
							{t(keys.ACCOUNT.ACCOUNT.SUBMITTED_PRODUCTS)}
						</CustomNavLink>
					</li>
					<li>
						<CustomNavLink
							to={`/adstore/?lng=${lngId}&favorite=true`}
							className={classes.textlink}
							onClick={() => dispatch(setQuotedOnly(false))}
						>
							{t(keys.ACCOUNT.ACCOUNT.FAVORITES_PRODUCTS)}
						</CustomNavLink>
					</li>
				</ul>
			</div>
			<div>
				<ul>
					<li>
						<CustomNavLink
							to={`/?lng=${lngId}`}
							className={classes.textlink}
							onClick={() => dispatch(logout())}
						>
							<Icon icon="material-symbols:logout-sharp" />
							{t(keys.AUTH.SIGN_IN.LOG_OUT)}
						</CustomNavLink>
					</li>
				</ul>
			</div>
		</article>
	);
};

Sidebar.defaultProps = {
	classes,
};

export default Sidebar;
