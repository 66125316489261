import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import classes from "./ColorMatch.module.scss";
import Resources from "../Segment/Resources/Resources";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	itemsColorsSelector,
	nuaniersSelector,
	colorsNuanciersSelector,
	colorsMatchSelector,
	itemColorSelector,
	loadingItemsColorsSelector,
	loadingNuaniersSelector,
	loadingColorsNuanciersSelector,
	loadingColorsMatchSelector,
	getNuanciers,
	getItemColors,
	getColorNuanciers,
	getColorMatch,
	getItemColor,
	loadingColorsSelector,
	colorsSelector,
	getColors,
} from "../../store/ColorMatch";
import {
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	FormControlLabel,
	IconButton,
	InputAdornment,
	RadioGroup,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Tabs,
	TextField,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import ColorCard from "../products/variant/variantGrid/colorLigne/colorCard/ColorCard";
import Radio from "@material-ui/core/Radio";
import CustomNavLink from "../ui/customNavLink/CustomNavLink";
import Button from "../ui/Button/Button";
import Loading from "../ui/loading/Loading";
import LinkTree from "../ui/LinkTree/LinkTree";
import emptyBox from "../../assets/svg/empty-box.svg";
import { keys } from "../../locales/localeskeys";
import encodeSlash from "../../hooks/encodeSlash";
import { constants } from "../../locales/constant";

const ColorMatch = () => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const search = `?lng=${lngId}`;

	const [searchParams, setSearchParams] = useSearchParams();

	const scrollColorRefManufacturer = useRef(null);
	const scrollColorRefSealent = useRef(null);
	const scrollColorMatchRefSealent = useRef(null);
	const scrollColorMatchRefManufacturer = useRef(null);

	const [serchColor, setSerchColor] = useState("");
	const [subSerchColor, setSubSerchColor] = useState("");
	const [order, setOrder] = React.useState("desc");
	const [manufacture, setManufacture] = useState(null);
	const [step, setStep] = useState(0);
	const [value, setValue] = useState(0);
	const [type, setType] = useState(0);
	const [radioValue, setRadioValue] = useState(0);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [selectedProduct, setSelectedProduct] = useState();
	const [selectedColor, setSelectedColor] = useState();
	const [filteredColorsMatch, setFilteredColorsMatch] = useState();
	const [filteredColorsNuanciers, setFilteredColorsNuanciers] = useState();
	const [showCard, setShowCard] = useState(false);
	const [displayColor, setDisplayColor] = useState(false);
	const [selectManufacturer, setSelectManufacturer] = useState(true);
	const [selectSealent, setSelectSealent] = useState(false);
	const [selectSearch, setSelectSearch] = useState(false);
	const [loadingNavigation, setLoadingNavigation] = useState(false);
	const [loadingFilterColorsNuanciers, setLoadingFilterColorsNuanciers] = useState(true);

	const itemsColors = useSelector(itemsColorsSelector);
	const nuanciers = useSelector(nuaniersSelector);
	const colorsNuanciers = useSelector(colorsNuanciersSelector);
	const colorsMatch = useSelector(colorsMatchSelector);
	const colors = useSelector(colorsSelector);
	const itemColor = useSelector(itemColorSelector);
	const loadingItemsColors = useSelector(loadingItemsColorsSelector);
	const loadingNuaniers = useSelector(loadingNuaniersSelector);
	const loadingColors = useSelector(loadingColorsSelector);
	const loadingColorsNuanciers = useSelector(loadingColorsNuanciersSelector);
	const loadingColorsMatch = useSelector(loadingColorsMatchSelector);

	const steps = 3;
	const widthPercentage = (((step > 3 ? 3 : step) - 1) / (steps - 1)) * 100;
	useEffect(() => {
		dispatch(getNuanciers());
		dispatch(getItemColors());
		dispatch(getColorNuanciers());
		dispatch(getColorMatch());
		dispatch(getColors());
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		if (manufacture && colorsMatch && colorsNuanciers) {
			setFilteredColorsNuanciers(
				colorsNuanciers
					.filter((colorNuancier) => {
						const matchesManufacture = colorNuancier?.nuancier?.tag_nuancier === manufacture?.tag_nuancier;
						const existsInColorsMatch = colorsMatch.some(
							(colorMatch) => colorMatch?.colorNuancier?.id === colorNuancier?.id
						);
						return matchesManufacture && existsInColorsMatch;
					})
					.sort((a, b) => a?.tag_nuancier_coul?.localeCompare(b?.tag_nuancier_coul))
			);
			setLoadingFilterColorsNuanciers(false);
		}
	}, [manufacture, colorsMatch, colorsNuanciers]);
	useEffect(() => {
		if (selectedColor) {
			if (selectSealent || selectSearch) {
				setFilteredColorsMatch(
					colorsMatch
						?.filter((colorMatch) => colorMatch?.color?.color_code === selectedColor?.color_code)
						.sort((a, b) => a.match_type - b.match_type)
				);
			}

			if (selectManufacturer) {
				setFilteredColorsMatch(
					colorsMatch
						?.filter((colorMatch) => colorMatch?.colorNuancier?.id === selectedColor?.id)
						.sort((a, b) => a.match_type - b.match_type)
				);
			}
		}
	}, [colorsMatch, selectedColor, selectSealent, selectManufacturer, selectSearch]);
	useEffect(() => {
		if (serchColor === "") {
			resetSelection();
		}
	}, [serchColor]);
	useEffect(() => {
		if (selectManufacturer && selectedColor && itemColor && loadingNavigation) {
			setSelectedProduct(itemColor[0]);
			navigate({
				pathname:
					"/adstore/variants/" +
					itemColor[0]?.item_detail?.erp_code +
					"/" +
					itemColor[0]?.item_detail?.brandErpCode +
					"/" +
					selectedColor?.color_code +
					"/",
			});
		}
	}, [selectManufacturer, selectedColor, itemColor, loadingNavigation]);
	useEffect(() => {
		if (step === 1 && selectManufacturer) {
			scrollColorRefManufacturer.current?.scrollIntoView({ behavior: "smooth" });
		}
		if (selectSealent && selectedProduct && step === 2) {
			scrollColorRefSealent.current?.scrollIntoView({ behavior: "smooth" });
		}
		if (selectSealent && selectedProduct && step === 3) {
			scrollColorMatchRefSealent.current?.scrollIntoView({ behavior: "smooth" });
		}
		if (step === 3 && selectManufacturer) {
			scrollColorMatchRefManufacturer.current?.scrollIntoView({ behavior: "smooth" });
		}
	}, [selectManufacturer, step, selectSealent, selectedProduct]);
	useEffect(() => {
		if (searchParams.get("searchType") === "SelectManufacturer" && selectManufacturer === false) {
			setSelectManufacturer(true);
			setType(0);
			setValue(0);
		}
		if (
			searchParams.get("manufacturerName") &&
			selectManufacturer === true &&
			nuanciers &&
			!loadingNuaniers &&
			!manufacture
		) {
			resetSelection();
			const tmpNuancier = nuanciers.find(
				(nuancier) => nuancier?.tag_nuancier === searchParams.get("manufacturerName")
			);
			if (tmpNuancier) {
				setStep(2);
				setManufacture(tmpNuancier);
				setDisplayColor(true);
			}
		}
		if (
			searchParams.get("selectColor") &&
			selectManufacturer === true &&
			manufacture &&
			!selectedColor &&
			!loadingColorsMatch &&
			!loadingColorsNuanciers
		) {
			const foundSelectedColorNuancier = colorsNuanciers.find(
				(color) => color?.id === searchParams.get("selectColor")
			);
			bestMatches(foundSelectedColorNuancier);
		}
		//--------------------------
		if (searchParams.get("searchType") === "SelectSealent" && selectSealent === false) {
			setSelectSealent(true);
			setType(1);
			setValue(1);
		}
		if (searchParams.get("itemColor") && selectSealent === true && !selectedProduct && !loadingItemsColors) {
			const foundItemColor = itemsColors.find(
				(product) => product?.item_detail?.erp_code === searchParams.get("itemColor")
			);
			selectProduct(foundItemColor);
		}
		if (searchParams.get("selectColor") && selectSealent === true && selectedProduct && !loadingColorsMatch) {
			const foundSelectedColor = selectedProduct.colors.find(
				(color) => color?.color_code === searchParams.get("selectColor")
			);
			bestMatches(foundSelectedColor);
		}
		//----------------------------- Search by Color Code
		if (searchParams.get("searchType") === "SelectSearch" && selectSearch === false) {
			setType(2);
			setValue(2);
			setSelectSearch(true);
		}
		if (
			searchParams.get("searchColorValue") &&
			selectSearch === true &&
			!loadingItemsColors &&
			!loadingColorsMatch
		) {
			setSerchColor(searchParams.get("searchColorValue").toString());
			handleApplySearch();
		}
	}, [
		searchParams,
		selectSealent,
		selectedProduct,
		selectSearch,
		serchColor,
		manufacture,
		nuanciers,
		selectedColor,
		selectManufacturer,
		loadingItemsColors,
		loadingColorsMatch,
		loadingNuaniers,
		loadingColorsNuanciers,
	]);

	const bestMatches = (color) => {
		setStep(3);
		setSelectedColor(color);
		setDisplayColor(false);
	};
	const resetSelection = () => {
		setStep(0);
		setSelectSealent(false);
		setSelectSearch(false);
		setLoadingNavigation(false);
		setSelectManufacturer(true);
		setLoadingFilterColorsNuanciers(true);
		setManufacture(null);
		setSelectedProduct(null);
		setSelectedColor(null);
		setFilteredColorsNuanciers(null);
		setSerchColor("");
		setSubSerchColor("");
	};
	const selectProduct = (product) => {
		resetSelection();
		setSelectManufacturer(false);
		setSelectSealent(true);
		setDisplayColor(true);
		setSelectedProduct(product);
		setStep(2);
		searchParams.set("searchType", "SelectSealent");
		searchParams.set("itemColor", product?.item_detail?.erp_code);
		setSearchParams(searchParams);
	};

	const handleChange = (newValue) => {
		setValue(newValue);
		swichType(newValue);
	};
	const handleChangeRadio = (event) => {
		setRadioValue(parseInt(event.target.value, 10));
		swichType(parseInt(event.target.value, 10));
	};
	const swichType = (value) => {
		setType(value);
		resetSelection();
		searchParams.delete("searchType");
		searchParams.delete("itemColor");
		searchParams.delete("searchColorValue");
		searchParams.delete("selectColor");
		searchParams.delete("manufacturerName");
		setSearchParams(searchParams);
		if (value === 0) {
			setSelectManufacturer(true);
			searchParams.set("searchType", "SelectManufacturer");
			setSearchParams(searchParams);
		} else if (value === 1) {
			setSelectSealent(true);
			searchParams.set("searchType", "SelectSealent");
			setSearchParams(searchParams);
		} else if (value === 2) {
			setSelectSearch(true);
			searchParams.set("searchType", "SelectSearch");
			setSearchParams(searchParams);
		}
	};
	const handleApplySearch = () => {
		if (serchColor !== "") {
			const tmp = serchColor.trim();
			resetSelection();
			setSelectSearch(true);
			setSelectSealent(true);
			setSelectManufacturer(false);
			setSerchColor(tmp);
			setStep(2);
			if (searchParams.get("searchColorValue") !== tmp) {
				searchParams.set("searchColorValue", tmp);
				setSearchParams(searchParams);
			}
			itemsColors.forEach((product) => {
				const color = product.colors.find((color) => color.color_code === serchColor);
				if (color) {
					setSelectedColor(color);
					setSelectedProduct(product);
					setStep(4);
				}
			});
		}
	};
	const resetSearch = () => {
		setSelectSealent(false);
		setStep(0);
		setSerchColor("");
		searchParams.delete("searchColorValue");
		setSearchParams(searchParams);
	};
	const colorLinkRedirect = () => {
		return `/adstore/variants/${selectedProduct?.item_detail?.erp_code}/${
			selectedProduct?.item_detail?.brandErpCode
		}/${selectedColor?.color_code}/${encodeSlash(search)}`;
	};
	const variantLinkProvider = (row) => {
		const color = colors.find((couleur) => couleur.color_code === row?.color?.color_code);
		setSelectedColor(row?.color);
		setSelectedColor(color);
		setLoadingNavigation(true);
		dispatch(getItemColor({ erp_code: color?.item?.erp_code }));
	};
	const getMatchTypeDetails = (matchType) => {
		return constants.colorMatch.matchTypeDetails[matchType] || { label_en: "", label_fr: "", backgroundColor: "" };
	};
	const resetSelectionColor = () => {
		setStep(2);
		setPage(0);
		setSelectedColor(null);
		setDisplayColor(true);
		searchParams.delete("selectColor");
		setSearchParams(searchParams);
	};
	const resetSelectionColorNuancier = () => {
		setStep(2);
		setPage(0);
		setSelectedColor(null);
		setDisplayColor(true);
		searchParams.delete("selectColor");
		setSearchParams(searchParams);
	};
	const handleRequestSort = () => {
		if (filteredColorsMatch && filteredColorsMatch.length > 0) {
			const isAsc = order === "asc";
			setOrder(isAsc ? "desc" : "asc");
			setFilteredColorsMatch(
				[...filteredColorsMatch].sort((a, b) => {
					if (isAsc) {
						return a.match_type - b.match_type;
					} else {
						return b.match_type - a.match_type;
					}
				})
			);
		}
	};
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	const getContrastYIQ = (hexcolor) => {
		hexcolor = hexcolor.replace("#", "");
		const r = parseInt(hexcolor.substring(0, 2), 16);
		const g = parseInt(hexcolor.substring(2, 4), 16);
		const b = parseInt(hexcolor.substring(4, 6), 16);
		const yiq = (r * 299 + g * 587 + b * 114) / 1000;
		return yiq >= 128 ? "black" : "white";
	};

	return (
		<div className={classes.layout}>
			<LinkTree
				tree={[
					{ label: t(keys.GLOBAL.NAVBAR.HOME), to: "/" },
					{ label: t(keys.TOOLS.TITLE), to: "/" },
					{ label: t(keys.GLOBAL.NAVBAR.COLORMATCH), to: `/tools/find-the-right-sealant/${search}` },
				]}
				className={classes.Tree}
			/>
			<h1 className={classes.title}>{t(keys.COLOR_MATCH.TITLE)}</h1>
			<div className={classes.subLayout1}>
				<h1 className={classes.subTitle}>{t(keys.COLOR_MATCH.SELECT_TYPE.TITLE)}</h1>
				<div className={classes.tabWeb}>
					<div className={classes.findSection}>
						<Button
							className={value !== 0 && classes.default}
							content={classes.btnContent}
							onClick={() => handleChange(0)}
						>
							{t(keys.COLOR_MATCH.SELECT_TYPE.TABS.FIRST)}
						</Button>
						<p>{t(keys.COLOR_MATCH.OPTION.OR)}</p>
						<Button
							className={value !== 1 && classes.default}
							content={classes.btnContent}
							onClick={() => handleChange(1)}
						>
							{t(keys.COLOR_MATCH.SELECT_TYPE.TABS.SECOND)}
						</Button>
					</div>
					<div className={`${classes.advancedSection} ${value > 1 && classes.selectedAdvancedSec}`}>
						<p onClick={() => handleChange(2)}>{t(keys.COLOR_MATCH.OPTION.ADVANCE_OPTION)}</p>
					</div>
				</div>
				<div className={classes.tabMobile}>
					<RadioGroup aria-label="filter" name="filter" value={radioValue} onChange={handleChangeRadio}>
						<FormControlLabel
							value={0}
							control={<Radio color="primary" />}
							label={t(keys.COLOR_MATCH.SELECT_TYPE.TABS.FIRST)}
						/>
						<FormControlLabel
							value={1}
							control={<Radio color="primary" />}
							label={t(keys.COLOR_MATCH.SELECT_TYPE.TABS.SECOND)}
						/>
						<FormControlLabel
							value={2}
							control={<Radio color="primary" />}
							label={t(keys.COLOR_MATCH.SELECT_TYPE.TABS.THIRD)}
						/>
					</RadioGroup>
				</div>
				{type === 0 && (
					<>
						<h1 className={classes.subTitle}>{t(keys.COLOR_MATCH.BRAND_TITLE)}</h1>
						<div className={classes.inputManufacturer}>
							{!loadingNuaniers && !loadingColorsNuanciers ? (
								<Autocomplete
									id="combo-box-demo"
									classes={{
										root: classes.inputRoot,
									}}
									onChange={(event, newValue) => {
										resetSelection();
										if (newValue) {
											setStep(2);
											setManufacture(newValue);
											setSelectManufacturer(true);
											setDisplayColor(true);
											if (searchParams.get("selectColor")) {
												searchParams.delete("selectColor");
											}
											searchParams.set("searchType", "SelectManufacturer");
											searchParams.set("manufacturerName", newValue?.tag_nuancier);
											setSearchParams(searchParams);
										}
									}}
									options={
										nuanciers
											? [...nuanciers].sort((a, b) =>
													a.tag_nuancier.localeCompare(b.tag_nuancier)
											  )
											: []
									}
									getOptionLabel={(nuancier) => nuancier?.tag_nuancier || ""}
									style={{ width: 300 }}
									renderInput={(params) => (
										<TextField
											{...params}
											InputLabelProps={{
												classes: {
													root: classes.MuiFormLabel,
												},
											}}
											label={t(keys.COLOR_MATCH.SELECT_TYPE.SEARCH_MANUFACTURER)}
											margin="normal"
										/>
									)}
								/>
							) : (
								<Loading type={"bounce"} />
							)}
						</div>
					</>
				)}
				{type === 1 && (
					<>
						<h1 className={classes.subTitle}>{t(keys.COLOR_MATCH.PRODUCT_TITLE)}</h1>
						<div className={classes.productTab}>
							{!loadingItemsColors && (
								<RadioGroup className={classes.productTabLayout}>
									{itemsColors &&
										[...itemsColors]
											.sort((a, b) => {
												const productCodes = ["1940", "4580", "4550", "4600", "4800"];
												return (
													productCodes.indexOf(a?.item_detail?.erp_code) -
													productCodes.indexOf(b?.item_detail?.erp_code)
												);
											})
											.map((product) => (
												<div
													key={product?.item_detail?.erp_code}
													onClick={() => selectProduct(product)}
													className={classes.card}
												>
													<FormControlLabel
														control={
															<Radio
																checked={
																	selectedProduct?.item_detail?.erp_code ===
																	product?.item_detail?.erp_code
																}
																color="primary"
															/>
														}
														className={classes.radioButton}
														label=""
														labelPlacement="end"
													/>
													<img
														className={classes.cardImage}
														src={product?.item_detail?.defaultImage}
														alt={product?.item_detail?.erp_code}
													/>
													<div
														className={classes.cardDetail}
														style={{
															backgroundColor:
																constants.colorMatch.products[
																	product?.item_detail?.erp_code
																],
														}}
													>
														{product?.item_detail?.erp_code}
													</div>
												</div>
											))}
								</RadioGroup>
							)}
							{loadingItemsColors && <Loading type={"bounce"} />}
						</div>
					</>
				)}
				{!loadingItemsColors && !loadingColorsMatch && type === 2 && (
					<div className={classes.rootSearch}>
						<TextField
							className={classes.searchField}
							label={t(keys.COLOR_MATCH.SELECT_TYPE.SEARCH_COLOR_CODE)}
							value={serchColor}
							onChange={(e) => {
								setSerchColor(e.target.value);
								if (searchParams.get("searchColorValue")) {
									searchParams.delete("searchColorValue");
									setSearchParams(searchParams);
								}
							}}
							classes={{
								root: `${classes.inputRoot} ${classes.labelTxt}`,
							}}
							onKeyPress={(ev) => {
								if (ev.key === "Enter") {
									handleApplySearch();
									ev.preventDefault();
								}
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										{serchColor && (
											<IconButton
												onClick={() => {
													setSerchColor("");
													searchParams.delete("searchColorValue");
													setSearchParams(searchParams);
												}}
											>
												<Icon className={classes.icon} icon="ic:baseline-clear" />
											</IconButton>
										)}
										{!serchColor && (
											<IconButton>
												<Icon className={classes.icon} icon="ic:baseline-search" />
											</IconButton>
										)}
										<IconButton
											onClick={() => {
												setShowCard(!showCard);
											}}
											onMouseEnter={() => setShowCard(true)}
											onMouseLeave={() => setShowCard(false)}
										>
											<Icon className={classes.icon} icon="material-symbols:info" />
										</IconButton>
										{showCard && (
											<Card
												classes={{
													root: classes.colorCodeInfo,
												}}
											>
												<CardActionArea>
													<CardMedia
														component="img"
														image={constants.colorMatch.color_code_info_image}
														alt="info image"
													/>
												</CardActionArea>
											</Card>
										)}
									</InputAdornment>
								),
							}}
							InputLabelProps={{
								classes: {
									root: classes.MuiFormLabel,
								},
							}}
						/>
						<Button
							className={classes.applyButton}
							onClick={() => {
								handleApplySearch();
							}}
						>
							{t(keys.COLOR_MATCH.SELECT_TYPE.APPLY)}
						</Button>
					</div>
				)}
				{loadingItemsColors && type === 2 && <Loading type={"bounce"} />}
			</div>
			{!loadingColorsNuanciers && selectManufacturer && step >= 1 && (
				<div ref={scrollColorRefManufacturer} className={classes.subLayout3}>
					<div
						className={classes.subTitle}
						onClick={() => {
							setDisplayColor(!displayColor);
						}}
					>
						<h1>{t(keys.COLOR_MATCH.SELECT_COLOR.TITLE)}</h1>
						<Icon
							className={classes.icon}
							icon={displayColor ? "mingcute:up-fill" : "mingcute:down-fill"}
						/>
					</div>
					{displayColor && (
						<>
							<div className={classes.descriptionColor}>
								{manufacture && <h1 className={classes.firstTitle}>{manufacture?.tag_nuancier}</h1>}
								{manufacture?.color_chart_url_en && manufacture?.color_chart_url_fr && (
									<div
										className={classes.secTtile}
										onClick={() =>
											window.open(
												lngId.includes("fr")
													? manufacture.color_chart_url_fr
													: manufacture.color_chart_url_en,
												"_blank"
											)
										}
									>
										<Icon className={classes.icon} icon="ic:baseline-download" />
										<h1>{t(keys.COLOR_MATCH.SELECT_COLOR.DOWNLOAD_COLOR_CHART)}</h1>
									</div>
								)}
							</div>
							<hr />

							{filteredColorsNuanciers && filteredColorsNuanciers.length > 0 ? (
								<>
									{filteredColorsNuanciers && filteredColorsNuanciers?.length > 5 && (
										<div className={classes.rootSearch}>
											<TextField
												className={classes.searchField}
												label={t(keys.COLOR_MATCH.SELECT_TYPE.SEARCH_COLOR_NAME)}
												value={subSerchColor}
												onChange={(e) => setSubSerchColor(e.target.value)}
												classes={{
													root: classes.inputRoot,
												}}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															{subSerchColor && (
																<IconButton onClick={() => setSubSerchColor("")}>
																	<Icon
																		className={classes.icon}
																		icon="ic:baseline-clear"
																	/>
																</IconButton>
															)}
															{!subSerchColor && (
																<IconButton>
																	<Icon
																		className={classes.icon}
																		icon="ic:baseline-search"
																	/>
																</IconButton>
															)}
														</InputAdornment>
													),
												}}
												InputLabelProps={{
													classes: {
														root: classes.MuiFormLabel,
													},
												}}
											/>
										</div>
									)}
									<div className={classes.CardContainer}>
										{filteredColorsNuanciers &&
											filteredColorsNuanciers
												.filter((colorNuancier) =>
													colorNuancier.tag_nuancier_coul
														.toLowerCase()
														.includes(subSerchColor.toLowerCase())
												)
												.map((colorNuancier) => (
													<div
														onClick={() => {
															bestMatches(colorNuancier);
															searchParams.set(
																"selectColor",
																encodeURIComponent(encodeSlash(colorNuancier?.id))
															);
															setSearchParams(searchParams);
														}}
														key={colorNuancier?.tag_nuancier_coul}
													>
														<ColorCard
															key={colorNuancier?.tag_nuancier_coul}
															id={colorNuancier?.tag_nuancier_coul}
															hex={colorNuancier?.nuancier_ref_html}
															image={colorNuancier?.nuancier_ref_img}
															displayImage={colorNuancier?.nuancier_ref_img && true}
															subtitle={colorNuancier?.tag_nuancier_coul}
															className={`${classes.cardStyle} ${
																colorNuancier?.id === selectedColor?.id
																	? classes.cardSelected
																	: ""
															}`}
															lockOnClick
															hideFavorite
														/>
													</div>
												))}
									</div>
									{filteredColorsNuanciers &&
										filteredColorsNuanciers.filter((colorNuancier) =>
											colorNuancier.tag_nuancier_coul
												.toLowerCase()
												.includes(subSerchColor.toLowerCase())
										).length === 0 && (
											<div className={classes.emptyState}>
												<div>
													<img src={emptyBox} alt="Colors not found" />
												</div>
												<div className={classes.title}>
													{t(keys.COLOR_MATCH.NO_RESULT.NO_COLOR)}
												</div>
												<div>
													<p>{t(keys.COLOR_MATCH.NO_RESULT.COULDNT_FIND)}</p>
													<p>{t(keys.COLOR_MATCH.NO_RESULT.TRY_AGAIN)}</p>
												</div>
												<Button
													className={classes.rstButton}
													onClick={() => setSubSerchColor("")}
												>
													{t(keys.COLOR_MATCH.NO_RESULT.RESET_SELECTION)}
												</Button>
											</div>
										)}
								</>
							) : loadingFilterColorsNuanciers && !filteredColorsNuanciers ? (
								<Loading type={"bounce"} />
							) : (
								<div>
									<div className={classes.emptyState}>
										<div>
											<img src={emptyBox} alt="Colors not found" />
										</div>
										<div className={classes.title}>{t(keys.COLOR_MATCH.NO_RESULT.NO_COLOR)}</div>
										<div>
											<p>{t(keys.COLOR_MATCH.NO_RESULT.COULDNT_FIND)}</p>
											<p>{t(keys.COLOR_MATCH.NO_RESULT.TRY_AGAIN)}</p>
										</div>
										<Button
											className={classes.rstButton}
											onClick={() => {
												resetSelection();
												searchParams.delete("manufacturerName");
												setSearchParams(searchParams);
											}}
										>
											{t(keys.COLOR_MATCH.NO_RESULT.RESET_SELECTION)}
										</Button>
									</div>
								</div>
							)}
						</>
					)}
				</div>
			)}
			{!loadingItemsColors && !selectSearch && selectSealent && selectedProduct && step >= 2 && (
				<div ref={scrollColorRefSealent} className={classes.subLayout3}>
					<div
						className={classes.subTitle}
						onClick={() => {
							setDisplayColor(!displayColor);
						}}
					>
						<h1>{t(keys.COLOR_MATCH.SELECT_COLOR.TITLE)}</h1>
						<Icon
							className={classes.icon}
							icon={displayColor ? "mingcute:up-fill" : "mingcute:down-fill"}
						/>
					</div>
					{displayColor && (
						<div>
							{selectedProduct?.colors && selectedProduct?.colors?.length > 5 && (
								<div className={classes.rootSearch}>
									<TextField
										className={classes.searchField}
										label={t(keys.COLOR_MATCH.SELECT_TYPE.SEARCH_COLOR_CODE)}
										value={subSerchColor}
										onChange={(e) => setSubSerchColor(e.target.value)}
										classes={{
											root: `${classes.inputRoot} ${classes.labelTxt}`,
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													{subSerchColor && (
														<IconButton onClick={() => setSubSerchColor("")}>
															<Icon className={classes.icon} icon="ic:baseline-clear" />
														</IconButton>
													)}
													{!subSerchColor && (
														<IconButton>
															<Icon className={classes.icon} icon="ic:baseline-search" />
														</IconButton>
													)}
													<IconButton
														onClick={() => {
															setShowCard(!showCard);
														}}
														onMouseEnter={() => setShowCard(true)}
														onMouseLeave={() => setShowCard(false)}
													>
														<Icon className={classes.icon} icon="material-symbols:info" />
													</IconButton>
													{showCard && (
														<Card
															classes={{
																root: classes.colorCodeInfo,
															}}
														>
															<CardActionArea>
																<CardMedia
																	component="img"
																	image={constants.colorMatch.color_code_info_image}
																	alt="info image"
																/>
															</CardActionArea>
														</Card>
													)}
												</InputAdornment>
											),
										}}
										InputLabelProps={{
											classes: {
												root: classes.MuiFormLabel,
											},
										}}
									/>
								</div>
							)}
							{selectedProduct?.colors?.length > 0 ? (
								<>
									<div className={classes.CardContainer}>
										{selectedProduct?.colors
											.slice()
											.sort((a, b) => {
												const parseCode = (code) => {
													const containsLetter = /[a-zA-Z]/.test(code.color_code);
													if (containsLetter) {
														return { containsLetter, parts: code.color_code.split("") };
													} else {
														const numericValue = parseInt(
															code.color_code.replace(/-/g, ""),
															10
														);
														return { containsLetter, numericValue };
													}
												};

												const aParsed = parseCode(a);
												const bParsed = parseCode(b);

												if (aParsed.containsLetter && !bParsed.containsLetter) return -1;
												if (!aParsed.containsLetter && bParsed.containsLetter) return 1;

												if (aParsed.containsLetter && bParsed.containsLetter) {
													for (
														let i = 0;
														i < Math.max(aParsed.parts.length, bParsed.parts.length);
														i++
													) {
														const aPart = aParsed.parts[i] || "";
														const bPart = bParsed.parts[i] || "";
														const result = aPart.localeCompare(bPart);
														if (result !== 0) return result;
													}
													return 0;
												} else {
													return aParsed.numericValue - bParsed.numericValue;
												}
											})
											.filter((color) =>
												color.color_code.toLowerCase().includes(subSerchColor.toLowerCase())
											)
											.map((color) => (
												<div
													onClick={() => {
														bestMatches(color);
														searchParams.set("selectColor", color?.color_code);
														setSearchParams(searchParams);
													}}
													key={color?.color_code}
												>
													<ColorCard
														key={color?.color_code}
														id={color?.color_code}
														hex={color?.ref_html}
														title={color?.color_code}
														subtitle={
															lngId.includes("fr") ? color?.couleur_fr : color?.couleur_en
														}
														className={`${classes.cardStyle} ${
															color?.id === selectedColor?.id ? classes.cardSelected : ""
														}`}
														lockOnClick
														hideFavorite
													/>
												</div>
											))}
									</div>
									{selectedProduct?.colors &&
										selectedProduct?.colors.filter((color) =>
											color.color_code.toLowerCase().includes(subSerchColor.toLowerCase())
										).length === 0 && (
											<div className={classes.emptyState}>
												<div>
													<img src={emptyBox} alt="Colors not found" />
												</div>
												<div className={classes.title}>
													{t(keys.COLOR_MATCH.NO_RESULT.NO_COLOR)}
												</div>
												<div>
													<p>{t(keys.COLOR_MATCH.NO_RESULT.COULDNT_FIND)}</p>
													<p>{t(keys.COLOR_MATCH.NO_RESULT.TRY_AGAIN)}</p>
												</div>
												<Button
													className={classes.rstButton}
													onClick={() => setSubSerchColor("")}
												>
													{t(keys.COLOR_MATCH.NO_RESULT.RESET_SELECTION)}
												</Button>
											</div>
										)}
								</>
							) : (
								<div className={classes.emptyState}>
									<div>
										<img src={emptyBox} alt="Color not found" />
									</div>
									<div className={classes.title}>{t(keys.COLOR_MATCH.NO_RESULT.NO_COLOR)}</div>
									<div>
										<p>{t(keys.COLOR_MATCH.NO_RESULT.COULDNT_FIND)}</p>
										<p>{t(keys.COLOR_MATCH.NO_RESULT.TRY_AGAIN)}</p>
									</div>
									<Button
										className={classes.rstButton}
										onClick={() => {
											resetSelection();
											searchParams.delete("selectColor");
											setSearchParams(searchParams);
										}}
									>
										{t(keys.COLOR_MATCH.NO_RESULT.RESET_SELECTION)}
									</Button>
								</div>
							)}
						</div>
					)}
				</div>
			)}
			{!loadingItemsColors && selectSealent && !selectedProduct && step >= 2 && (
				<div className={classes.emptyState}>
					<div>
						<img src={emptyBox} alt="Colors not found" />
					</div>
					<div className={classes.title}>{t(keys.COLOR_MATCH.NO_RESULT.NO_COLOR)}</div>
					<div>
						<p>{t(keys.COLOR_MATCH.NO_RESULT.COULDNT_FIND)}</p>
						<p>{t(keys.COLOR_MATCH.NO_RESULT.TRY_AGAIN)}</p>
					</div>
					<Button className={classes.rstButton} onClick={resetSearch}>
						{t(keys.COLOR_MATCH.NO_RESULT.RESET_SELECTION)}
					</Button>
				</div>
			)}

			{!loadingColorsMatch && step >= 3 && selectSealent && selectedProduct && nuanciers && selectedColor && (
				<div ref={scrollColorMatchRefSealent} className={classes.subLayout4}>
					<h1 className={classes.title}>{t(keys.COLOR_MATCH.BEST_MATCH.TITLE)}</h1>
					{filteredColorsMatch ? (
						<div>
							<div className={classes.subTitle}>{t(keys.COLOR_MATCH.BEST_MATCH.FIND_COLO_MATCH)}</div>
							<div className={classes.productSelect}>
								<div className={classes.productSection}>
									<CustomNavLink className={classes.boxStyle} to={colorLinkRedirect()}>
										<Card
											classes={{
												root: classes.productBox,
											}}
										>
											<CardActionArea>
												<CardMedia
													component="img"
													height="75%"
													image={selectedProduct?.item_detail?.defaultImage}
												/>
												{selectedProduct?.item_detail?.erp_code && (
													<CardContent
														classes={{
															root: classes.productCode,
														}}
														style={{
															backgroundColor: selectedColor?.ref_html,
															color: getContrastYIQ(selectedColor?.ref_html),
														}}
													>
														<p>{selectedColor?.color_code}</p>
														<p>
															{lngId.includes("fr")
																? selectedColor?.couleur_fr
																: selectedColor?.couleur_en}
														</p>
													</CardContent>
												)}
											</CardActionArea>
										</Card>
									</CustomNavLink>
									{!selectSearch && (
										<div className={classes.link} onClick={resetSelectionColor}>
											{t(keys.COLOR_MATCH.BEST_MATCH.RESET_SELECTION)}
										</div>
									)}
								</div>

								<div className={classes.matchTable}>
									<TableContainer>
										<Table
											aria-label="sortable table"
											classes={{
												root: classes.tableRoot,
											}}
										>
											<TableHead
												classes={{
													root: classes.tabHeader,
												}}
											>
												<TableRow>
													<TableCell classes={{ root: classes.cell }}>
														<div className={classes.txtheader}>
															{t(keys.COLOR_MATCH.BEST_MATCH.TAB.TH1)}
														</div>
													</TableCell>
													<TableCell classes={{ root: classes.cell }}>
														<div className={classes.txtheader}>
															{t(keys.COLOR_MATCH.BEST_MATCH.TAB.TH2)}
														</div>
													</TableCell>
													<TableCell classes={{ root: classes.cell }}>
														<TableSortLabel
															active={true}
															direction={order}
															classes={{ icon: classes.icon, root: classes.txtheader }}
															onClick={handleRequestSort}
														>
															<div className={classes.txtheader}>
																{t(keys.COLOR_MATCH.BEST_MATCH.TAB.TH3)}
															</div>
														</TableSortLabel>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody
												classes={{
													root: classes.tabBody,
												}}
											>
												{filteredColorsMatch
													.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
													.map(
														(row, index) =>
															row?.colorNuancier?.nuancier?.tag_nuancier &&
															row?.colorNuancier?.tag_nuancier_coul && (
																<TableRow key={index}>
																	<TableCell classes={{ root: classes.cell }}>
																		{row?.colorNuancier?.nuancier?.tag_nuancier}
																	</TableCell>
																	<TableCell classes={{ root: classes.cell }}>
																		{row?.colorNuancier?.tag_nuancier_coul}
																	</TableCell>
																	<TableCell classes={{ root: classes.cell }}>
																		<div className={classes.accMatch}>
																			<div
																				className={classes.tempon}
																				style={{
																					backgroundColor:
																						getMatchTypeDetails(
																							row?.match_type
																						)?.backgroundColor,
																					border: getMatchTypeDetails(
																						row?.match_type
																					)?.border,
																					color: getMatchTypeDetails(
																						row?.match_type
																					)?.color,
																				}}
																			>
																				{row?.match_type &&
																					(lngId.includes("fr")
																						? getMatchTypeDetails(
																								row?.match_type
																						  )?.label_fr
																						: getMatchTypeDetails(
																								row?.match_type
																						  )?.label_en)}
																			</div>
																			<div className={classes.colorContent}>
																				<div className={classes.colorDetails}>
																					<span>
																						{t(
																							keys.COLOR_MATCH.SELECT_TYPE
																								.SIDING
																						)}
																					</span>
																					<span>
																						{t(
																							keys.COLOR_MATCH.SELECT_TYPE
																								.SCELLANT
																						)}
																					</span>
																				</div>
																				<div className={classes.colorBox}>
																					<div
																						className={classes.colorLeft}
																						style={{
																							backgroundColor:
																								row?.colorNuancier
																									?.nuancier_ref_html,
																						}}
																					/>
																					<div
																						className={classes.colorRight}
																						style={{
																							backgroundColor:
																								row?.color?.ref_html,
																						}}
																					/>
																				</div>
																			</div>
																		</div>
																	</TableCell>
																</TableRow>
															)
													)}
												{filteredColorsMatch?.length == 0 && (
													<TableRow>
														<TableCell colSpan={6}>
															<div style={{ textAlign: "center" }}>
																{t(keys.COLOR_MATCH.BEST_MATCH.NO_MATCH_FOUND)}
															</div>
														</TableCell>
													</TableRow>
												)}
											</TableBody>
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										component="div"
										count={filteredColorsMatch.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										labelRowsPerPage={t(keys.COLOR_MATCH.BEST_MATCH.TAB.LIGNE_PER_PAGE)}
										labelDisplayedRows={({ from, to, count }) =>
											`${from}-${to} ${t(keys.COLOR_MATCH.BEST_MATCH.TAB.OF)} ${
												count !== -1
													? count
													: `${t(keys.COLOR_MATCH.BEST_MATCH.TAB.PLUS)} ${to}`
											}`
										}
									/>
								</div>
							</div>
							<div className={classes.legendeMatch}>
								<div className={classes.title}>
									{t(keys.COLOR_MATCH.BEST_MATCH.MATCH_ACCURENCY_TITLE)}
								</div>
								<div className={`${classes.matchRow} ${classes.subtitle}`}>
									<p>
										<strong>{t(keys.COLOR_MATCH.BEST_MATCH.DELTA)}</strong>{" "}
										{t(keys.COLOR_MATCH.BEST_MATCH.MATCH_ACCURENCY_DESCRIPTION)}
									</p>
								</div>
								<div className={classes.matchRow}>
									<div className={classes.temponContainer}>
										<div
											className={classes.tempon}
											style={{
												backgroundColor: getMatchTypeDetails(1)?.backgroundColor,
												border: getMatchTypeDetails(1)?.border,
												color: getMatchTypeDetails(1)?.color,
											}}
										>
											{lngId.includes("fr")
												? getMatchTypeDetails(1)?.label_fr
												: getMatchTypeDetails(1)?.label_en}
										</div>
									</div>
									<div className={classes.description}>ΔE 0.00 – 0.75</div>
								</div>
								<div className={classes.matchRow}>
									<div className={classes.temponContainer}>
										<div
											className={classes.tempon}
											style={{
												backgroundColor: getMatchTypeDetails(2)?.backgroundColor,
												border: getMatchTypeDetails(2)?.border,
												color: getMatchTypeDetails(2)?.color,
											}}
										>
											{lngId.includes("fr")
												? getMatchTypeDetails(2)?.label_fr
												: getMatchTypeDetails(2)?.label_en}
										</div>
									</div>
									<div className={classes.description}>ΔE 0.75 – 1.50</div>
								</div>
								<div className={classes.matchRow}>
									<div className={classes.temponContainer}>
										<div
											className={classes.tempon}
											style={{
												backgroundColor: getMatchTypeDetails(3)?.backgroundColor,
												border: getMatchTypeDetails(3)?.border,
												color: getMatchTypeDetails(3)?.color,
											}}
										>
											{lngId.includes("fr")
												? getMatchTypeDetails(3)?.label_fr
												: getMatchTypeDetails(3)?.label_en}
										</div>
									</div>
									<div className={classes.description}>ΔE 1.50 – 3.00</div>
								</div>
								<div className={classes.matchRow}>
									<div className={classes.temponContainer}>
										<div
											className={classes.tempon}
											style={{
												backgroundColor: getMatchTypeDetails(4)?.backgroundColor,
												border: getMatchTypeDetails(4)?.border,
												color: getMatchTypeDetails(4)?.color,
											}}
										>
											{lngId.includes("fr")
												? getMatchTypeDetails(4)?.label_fr
												: getMatchTypeDetails(4)?.label_en}
										</div>
									</div>
									<div className={classes.description}>
										{t(keys.COLOR_MATCH.BEST_MATCH.MATCH_ACCURENCY_DETAIL)}
									</div>
								</div>
								<div className={classes.matchRow}>
									<div className={classes.temponContainer}>
										<div
											className={classes.tempon}
											style={{
												backgroundColor: "#C0C0C0",
												border: getMatchTypeDetails(5)?.border,
												color: getMatchTypeDetails(5)?.color,
											}}
										>
											{lngId.includes("fr")
												? getMatchTypeDetails(5)?.label_fr
												: getMatchTypeDetails(5)?.label_en}
										</div>
									</div>
									<div className={classes.description}>
										{t(keys.COLOR_MATCH.BEST_MATCH.MATCH_METALLIC_DETAIL)}
									</div>
								</div>
							</div>
							<div className={classes.infoNote}>
								<Alert severity="info">{t(keys.COLOR_MATCH.BEST_MATCH.ALERT)}</Alert>
							</div>
						</div>
					) : (
						<div className={classes.emptyState}>
							<div>
								<img src={emptyBox} alt="Color not found" />
							</div>
							<div className={classes.title}>{t(keys.COLOR_MATCH.NO_RESULT.NO_COLOR)}</div>
							<div>
								<p>{t(keys.COLOR_MATCH.NO_RESULT.COULDNT_FIND)}</p>
								<p>{t(keys.COLOR_MATCH.NO_RESULT.TRY_AGAIN)}</p>
							</div>
							<Button className={classes.rstButton} onClick={resetSelectionColor}>
								{t(keys.COLOR_MATCH.NO_RESULT.RESET_SELECTION)}
							</Button>
						</div>
					)}
				</div>
			)}
			{!loadingColorsMatch && !loadingColors && step >= 3 && selectManufacturer && nuanciers && selectedColor && (
				<div ref={scrollColorMatchRefManufacturer} className={classes.subLayout4}>
					<h1 className={classes.title}>{t(keys.COLOR_MATCH.BEST_MATCH.TITLE)}</h1>
					{filteredColorsMatch ? (
						<div>
							<div className={classes.productSelect}>
								{!loadingNavigation && (
									<>
										<div className={classes.productSection}>
											<ColorCard
												hex={selectedColor?.nuancier_ref_html}
												image={selectedColor?.nuancier_ref_img}
												displayImage={selectedColor?.nuancier_ref_img && true}
												subtitle={selectedColor?.tag_nuancier_coul}
												className={`${classes.cardStyle} ${classes.customWith} ${classes.enableClick}`}
												lockOnClick
												hideFavorite
											/>
											<div className={classes.link} onClick={resetSelectionColorNuancier}>
												{t(keys.COLOR_MATCH.BEST_MATCH.RESET_SELECTION)}
											</div>
										</div>
										<div className={classes.matchTable}>
											<TableContainer>
												<Table
													aria-label="sortable table"
													classes={{
														root: classes.tableRoot,
													}}
												>
													<TableHead
														classes={{
															root: classes.tabHeader,
														}}
													>
														<TableRow>
															<TableCell classes={{ root: classes.txtheader }}>
																{t(keys.COLOR_MATCH.BEST_MATCH.TAB.TH2)}
															</TableCell>
															<TableCell classes={{ root: classes.cell }}>
																<TableSortLabel
																	direction={order}
																	active={true}
																	classes={{
																		icon: classes.icon,
																		root: classes.txtheader,
																	}}
																	onClick={handleRequestSort}
																>
																	<div className={classes.txtheader}>
																		{t(keys.COLOR_MATCH.BEST_MATCH.TAB.TH3)}
																	</div>
																</TableSortLabel>
															</TableCell>
															<TableCell classes={{ root: classes.txtheader }}>
																{t(keys.COLOR_MATCH.BEST_MATCH.TAB.TH4)}
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody
														classes={{
															root: classes.tabBody,
														}}
													>
														{filteredColorsMatch
															.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
															.map(
																(row, index) =>
																	row?.color?.couleur_fr &&
																	row?.color?.couleur_en && (
																		<TableRow key={index}>
																			<TableCell classes={{ root: classes.cell }}>
																				<div
																					className={classes.colorLink}
																					onClick={() => {
																						variantLinkProvider(row);
																					}}
																				>
																					{row?.color?.color_code +
																						" " +
																						(lngId.includes("fr")
																							? row?.color?.couleur_fr
																							: row?.color?.couleur_en)}
																				</div>
																			</TableCell>
																			<TableCell classes={{ root: classes.cell }}>
																				<div className={classes.accMatch}>
																					<div
																						className={classes.tempon}
																						style={{
																							backgroundColor:
																								getMatchTypeDetails(
																									row?.match_type
																								)?.backgroundColor,
																							border: getMatchTypeDetails(
																								row?.match_type
																							)?.border,
																							color: getMatchTypeDetails(
																								row?.match_type
																							)?.color,
																						}}
																					>
																						{row?.match_type &&
																							(lngId.includes("fr")
																								? getMatchTypeDetails(
																										row?.match_type
																								  )?.label_fr
																								: getMatchTypeDetails(
																										row?.match_type
																								  )?.label_en)}
																					</div>
																					<div
																						className={classes.colorContent}
																					>
																						<div
																							className={
																								classes.colorDetails
																							}
																						>
																							<span>
																								{t(
																									keys.COLOR_MATCH
																										.SELECT_TYPE
																										.SIDING
																								)}
																							</span>
																							<span>
																								{t(
																									keys.COLOR_MATCH
																										.SELECT_TYPE
																										.SCELLANT
																								)}
																							</span>
																						</div>
																						<div
																							className={classes.colorBox}
																						>
																							<div
																								className={
																									classes.colorLeft
																								}
																								style={{
																									backgroundColor:
																										row
																											?.colorNuancier
																											?.nuancier_ref_html,
																								}}
																							></div>
																							<div
																								className={
																									classes.colorRight
																								}
																								style={{
																									backgroundColor:
																										row?.color
																											?.ref_html,
																								}}
																							></div>
																						</div>
																					</div>
																				</div>
																			</TableCell>
																			<TableCell
																				classes={{ root: classes.cellWith }}
																			>
																				<div className={classes.centreBtn}>
																					<Button
																						className={classes.buyBtn}
																						onClick={() => {
																							variantLinkProvider(row);
																						}}
																					>
																						{t(
																							keys.COLOR_MATCH.BEST_MATCH
																								.TAB.BUY
																						)}
																					</Button>
																				</div>
																			</TableCell>
																		</TableRow>
																	)
															)}
														{filteredColorsMatch?.length == 0 && (
															<TableRow>
																<TableCell colSpan={6}>
																	<div style={{ textAlign: "center" }}>
																		{t(keys.COLOR_MATCH.BEST_MATCH.NO_MATCH_FOUND)}
																	</div>
																</TableCell>
															</TableRow>
														)}
													</TableBody>
												</Table>
											</TableContainer>
											<TablePagination
												rowsPerPageOptions={[5, 10, 25]}
												component="div"
												count={filteredColorsMatch.length}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onRowsPerPageChange={handleChangeRowsPerPage}
												labelRowsPerPage={t(keys.COLOR_MATCH.BEST_MATCH.TAB.LIGNE_PER_PAGE)}
												labelDisplayedRows={({ from, to, count }) =>
													`${from}-${to} ${t(keys.COLOR_MATCH.BEST_MATCH.TAB.OF)} ${
														count !== -1
															? count
															: `${t(keys.COLOR_MATCH.BEST_MATCH.TAB.PLUS)} ${to}`
													}`
												}
											/>
										</div>
									</>
								)}
								{loadingNavigation && <Loading type={"bounce"} />}
							</div>
							<div className={classes.legendeMatch}>
								<div className={classes.title}>
									{t(keys.COLOR_MATCH.BEST_MATCH.MATCH_ACCURENCY_TITLE)}
								</div>
								<div className={`${classes.matchRow} ${classes.subtitle}`}>
									<p>
										<strong>{t(keys.COLOR_MATCH.BEST_MATCH.DELTA)}</strong>{" "}
										{t(keys.COLOR_MATCH.BEST_MATCH.MATCH_ACCURENCY_DESCRIPTION)}
									</p>
								</div>
								<div className={classes.matchRow}>
									<div className={classes.temponContainer}>
										<div
											className={classes.tempon}
											style={{
												backgroundColor: getMatchTypeDetails(1)?.backgroundColor,
												border: getMatchTypeDetails(1)?.border,
												color: getMatchTypeDetails(1)?.color,
											}}
										>
											{lngId.includes("fr")
												? getMatchTypeDetails(1)?.label_fr
												: getMatchTypeDetails(1)?.label_en}
										</div>
									</div>
									<div className={classes.description}>ΔE 0.00 – 0.75</div>
								</div>
								<div className={classes.matchRow}>
									<div className={classes.temponContainer}>
										<div
											className={classes.tempon}
											style={{
												backgroundColor: getMatchTypeDetails(2)?.backgroundColor,
												border: getMatchTypeDetails(2)?.border,
												color: getMatchTypeDetails(2)?.color,
											}}
										>
											{lngId.includes("fr")
												? getMatchTypeDetails(2)?.label_fr
												: getMatchTypeDetails(2)?.label_en}
										</div>
									</div>
									<div className={classes.description}>ΔE 0.75 – 1.50</div>
								</div>
								<div className={classes.matchRow}>
									<div className={classes.temponContainer}>
										<div
											className={classes.tempon}
											style={{
												backgroundColor: getMatchTypeDetails(3)?.backgroundColor,
												border: getMatchTypeDetails(3)?.border,
												color: getMatchTypeDetails(3)?.color,
											}}
										>
											{lngId.includes("fr")
												? getMatchTypeDetails(3)?.label_fr
												: getMatchTypeDetails(3)?.label_en}
										</div>
									</div>
									<div className={classes.description}>ΔE 1.50 – 3.00</div>
								</div>
								<div className={classes.matchRow}>
									<div className={classes.temponContainer}>
										<div
											className={classes.tempon}
											style={{
												backgroundColor: getMatchTypeDetails(4)?.backgroundColor,
												border: getMatchTypeDetails(4)?.border,
												color: getMatchTypeDetails(4)?.color,
											}}
										>
											{lngId.includes("fr")
												? getMatchTypeDetails(4)?.label_fr
												: getMatchTypeDetails(4)?.label_en}
										</div>
									</div>
									<div className={classes.description}>
										{t(keys.COLOR_MATCH.BEST_MATCH.MATCH_ACCURENCY_DETAIL)}
									</div>
								</div>
								<div className={classes.matchRow}>
									<div className={classes.temponContainer}>
										<div
											className={classes.tempon}
											style={{
												backgroundColor: getMatchTypeDetails(5)?.backgroundColor,
												border: getMatchTypeDetails(5)?.border,
												color: getMatchTypeDetails(5)?.color,
											}}
										>
											{lngId.includes("fr")
												? getMatchTypeDetails(5)?.label_fr
												: getMatchTypeDetails(5)?.label_en}
										</div>
									</div>
									<div className={classes.description}>
										{t(keys.COLOR_MATCH.BEST_MATCH.MATCH_METALLIC_DETAIL)}
									</div>
								</div>
							</div>
							<div className={classes.infoNote}>
								<Alert severity="info">{t(keys.COLOR_MATCH.BEST_MATCH.ALERT)}</Alert>
							</div>
						</div>
					) : (
						<div className={classes.emptyState}>
							<div>
								<img src={emptyBox} alt="Color not found" />
							</div>
							<div className={classes.title}>{t(keys.COLOR_MATCH.NO_RESULT.NO_COLOR)}</div>
							<div>
								<p>{t(keys.COLOR_MATCH.NO_RESULT.COULDNT_FIND)}</p>
								<p>{t(keys.COLOR_MATCH.NO_RESULT.TRY_AGAIN)}</p>
							</div>
							<Button className={classes.rstButton} onClick={resetSelectionColor}>
								{t(keys.COLOR_MATCH.NO_RESULT.RESET_SELECTION)}
							</Button>
						</div>
					)}
				</div>
			)}
			{manufacture?.partner_url && manufacture?.visit_partner_img && manufacture?.visit_partner_txt && (
				<CustomNavLink to={manufacture?.partner_url}>
					<div className={classes.visitPartner}>
						<img src={manufacture?.visit_partner_img} />
						<div className={classes.visitPage}>{manufacture?.visit_partner_txt}</div>
					</div>
				</CustomNavLink>
			)}
		</div>
	);
};

export default ColorMatch;
